const Csv = (props) => {
  const { width = "18", height = "20" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2215 0.09375H2.14751C0.961473 0.09375 0 1.05522 0 2.24126V17.7638C0 18.9498 0.961472 19.9113 2.14751 19.9113H13.3765C14.5625 19.9113 15.524 18.9498 15.524 17.7638V3.39626L12.2215 0.09375Z"
        fill="#53AC80"
      />
      <path
        d="M12.2207 1.96459C12.2207 2.75528 12.8617 3.39626 13.6524 3.39626H15.5232L12.2207 0.09375V1.96459Z"
        fill="#AEE0C5"
      />
      <rect
        x="3.06055"
        y="10.4609"
        width="14.9392"
        height="2.1342"
        rx="1.0671"
        fill="#80C7A4"
      />
      <rect
        x="3.06055"
        y="6.49609"
        width="7.62204"
        height="2.1342"
        rx="1.0671"
        fill="#80C7A4"
      />
      <rect
        x="3.06055"
        y="14.4219"
        width="14.9392"
        height="2.1342"
        rx="1.0671"
        fill="#80C7A4"
      />
    </svg>
  );
};

export default Csv;
