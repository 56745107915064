import { useEffect, useReducer, useState } from "react";
import {
  replicationFrequency,
  sampleSchemas,
} from "../constants/data.constants";
import ArrowDown from "../assets/icons/ArrowDown";
import { createConnectionRequest } from "../../api/dataServices";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
let schemaCopy: any = {};

const MySqlConnectorFinal = ({
  sourceId,
  setPageNumber,
  name,
  sourceSchema,
  navigate,
}) => {
  useEffect(() => {
    const schemas = [];
    sourceSchema.map((schema) => {
      let s = { name: schema.name, status: false };
      schemas.push(s);
      schemaCopy[schema.name] = schema;
    });
    setSchemaList(schemas);
  }, []);

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: false,
          },
        };
      case "SUBMIT":
        const errors: any = {};
        // if (!state.destinationNamespace) {
        //   errors.destinationNamespace = true;
        // }
        // if (!state.destinationPrefix) {
        //   errors.destinationPrefix = true;
        // }
        return {
          ...state,
          errors: {
            ...errors,
          },
        };

      case "reinitialize":
        return { ...state, ...action.value };
      default:
        return state;
    }
  };

  const connectionFormInitValue = {
    replicationFrequency: replicationFrequency[0],
    destinationNamespace: "",
    destinationPrefix: "",
    selectedStreams: [],
    errors: {
      replicationFrequency: false,
      destinationNamespace: false,
      destinationPrefix: false,
      selectedStreams: false,
    },
  };

  const handleFormChange = (e) => {
    let { name, value } = e.target;
    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value: value,
      },
    });
  };

  const [connectionForm, dispatch] = useReducer(
    reducer,
    connectionFormInitValue
  );
  const [frequencySelectorView, setFrequencySelectorView] = useState(false);
  const [schemaList, setSchemaList] = useState([]);
  const [connectionButtonStatus, setConnectionButtonStatus] = useState(false);

  const isValidaForm = (updatedState) => {
    const errors = updatedState.errors;
    let status = true;
    Object.keys(errors).map((key) => {
      if (errors[key] === true) {
        status = false;
        return;
      }
    });
    return status;
  };

  const getSelectedStreams = () => {
    let streams = [];
    schemaList.map((schema) => {
      if (schema.status) {
        streams.push({ stream: schemaCopy[schema.name] });
      }
    });
    return streams;
  };

  const createConnection = () => {
    const updatedState = reducer(connectionForm, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });
    if (!isValidaForm(updatedState)) return;
    let streams = getSelectedStreams();
    if (streams.length === 0) {
      Notify({
        type: "failure",
        heading: "Failed",
        message: "No streams selected",
      });
      return;
    }
    let data = {
      name: name,
      type: "MySQL",
      scheduleType: connectionForm.replicationFrequency.value,
      scheduleData:
        connectionForm.replicationFrequency.value === "basic"
          ? {
              basicSchedule: {
                units: connectionForm.replicationFrequency.units,
                timeUnit: connectionForm.replicationFrequency.timeUnit,
              },
            }
          : {},
      sourceId: sourceId,
      streams: getSelectedStreams(),
    };
    console.log(data);
    setConnectionButtonStatus(true);
    createConnectionRequest(data)
      .then((response) => {
        setConnectionButtonStatus(false);
        if (!response.error) {
          console.log(response);
          Notify({
            type: "success",
            heading: "Successful",
            message: response.response,
          });
          navigate("/data");
        } else {
          console.log(response.response);
        }
      })
      .catch((error) => {
        setConnectionButtonStatus(false);
        console.log(error);
      });
  };

  const toggleSchema = (index) => {
    let s = schemaList;
    s[index].status = !s[index].status;
    setSchemaList([...s]);
  };

  return (
    <>
      <div className="mt-6">
        <div className="text-gray-70 text-sm font-semibold leading-6">
          Replication frequency
        </div>
        <div className="border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px] relative cursor-pointer">
          <div
            className="flex items-center justify-between h-full mr-3 text-sm leading-6 text-gray-700"
            onClick={() => setFrequencySelectorView((status) => !status)}
          >
            <div>{connectionForm.replicationFrequency.label}</div>
            <div>
              <ArrowDown />
            </div>
          </div>
          {frequencySelectorView && (
            <div className="absolute h-[232px] w-full bg-white overflow-auto top-[42px] left-0 rounded-lg shadow-lg z-10">
              {replicationFrequency.map((frequency) => {
                return (
                  <div key={frequency.label}>
                    <div
                      className="cursor-pointer py-2 px-3 text-sm leading-6 text-gray-700 hover:bg-hover-offset"
                      onClick={() => {
                        dispatch({
                          type: "CHANGE",
                          payload: {
                            name: "replicationFrequency",
                            value: frequency,
                          },
                        });
                        setFrequencySelectorView(false);
                      }}
                    >
                      {frequency.label}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex gap-4 w-full">
        <div className="mt-6 w-full">
          <div className="text-gray-70 text-sm font-semibold leading-6">
            Destination namespace
          </div>
          <div
            className={`border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px] ${
              connectionForm.errors.destinationNamespace && "border-red-500"
            }`}
          >
            <input
              className="outline-none border-none h-full w-full text-sm"
              placeholder="businesscloud"
              type="text"
              value={connectionForm.destinationNamespace}
              name="destinationNamespace"
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className="mt-6 w-full">
          <div className="text-gray-70 text-sm font-semibold leading-6">
            Destination prefix
          </div>
          <div
            className={`border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px] ${
              connectionForm.errors.destinationPrefix && "border-red-500"
            }`}
          >
            <input
              className="outline-none border-none h-full w-full text-sm"
              placeholder="businesscloud"
              type="text"
              value={connectionForm.destinationPrefix}
              name="destinationPrefix"
              onChange={handleFormChange}
            />
          </div>
        </div>
      </div> */}
      <div className="flex gap-4 w-full">
        <div className="mt-6 w-full">
          <div className="text-gray-70 text-sm font-semibold leading-6">
            Enable streams to sync
          </div>
        </div>
        <div className="mt-6 w-full">
          <div className="text-gray-70 text-sm font-semibold leading-6">
            Enable streams to sync
          </div>
        </div>
      </div>
      {schemaList.map((schema, i) => {
        return (
          <div key={schema.name} className="flex gap-4 w-full">
            <div className="mt-6 w-full flex gap-2 items-center">
              <div
                className={`w-[36px] h-[20px] rounded-full flex p-0.5 items-center cursor-pointer ${
                  schema.status
                    ? "justify-end bg-gray-200"
                    : "justify-start bg-gray-400"
                }`}
                onClick={() => toggleSchema(i)}
              >
                <div className="w-[16px] h-[16px] bg-white rounded-full"></div>
              </div>
              <div className="text-gray-70 text-sm font-semibold leading-6">
                {schema.name}
                {schema.status}
              </div>
            </div>
            <div className="mt-6 w-full flex justify-end">
              <div className="text-gray-70 text-sm font-semibold leading-6 h-[40px] flex gap-3 bg-gray-10 px-5 py-3 rounded-full items-center w-max relative cursor-pointer group">
                <div>Full refresh - Overwrite</div>
                {/* <div>
        <ArrowDown />
      </div> */}
                <div className="w-full bg-white rounded-lg shadow-md py-2 absolute top-[40px] left-0 hidden">
                  <div className="py-2 px-4">Full refresh - Overwrite</div>
                  <div className="py-2 px-4">Full refresh - Append</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="mt-6">
        <button
          onClick={() => createConnection()}
          className="text-sm font-semibold leading-4 px-5 py-3 cursor-pointer rounded-md bg-gray-200 text-white"
          disabled={connectionButtonStatus}
        >
          Finish setup
        </button>
      </div>
    </>
  );
};

export default MySqlConnectorFinal;
