import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Csv from "../../common/assets/icons/Csv";
import { colorCode } from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import { getConnectors } from "../../api/dataServices";
import * as Sentry from "@sentry/react";
import {
  addGAEventListener,
  pageLoadEvent,
} from "../../../../marketing-cloud-container/src/ga-events";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import PaginationComp from "../../../../engagements/src/common/components/pagination";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";

const Data = ({ dispatch, setShowCommonLoader, navigate, tenantContext }) => {
  const [showSourceMenu, setShowSourceMenu] = useState(false);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [connectorList, setConnectorList] = useState([
    { id: "csv", name: "CSV Uploads", status: "active" },
  ]);
  const sourceMenu = useRef(null);
  const statusMenu = useRef(null);
  const [showSegmentSideMenu, setShowSegmentSideMenu] = useState(false);
  const [totalListLength, setTotalListLength] = useState(0);

  useEffect(() => {
    fetchConnectors(0, 10);
    pageLoadEvent("data_page");
  }, []);

  useEffect(() => {
    addGAEventListener();
  }, [showSourceMenu, showStatusMenu]);

  const closeOpenMenus = (e) => {
    if (
      sourceMenu.current &&
      showSourceMenu &&
      !sourceMenu.current.contains(e.target)
    ) {
      setShowSourceMenu(false);
    }

    if (
      statusMenu.current &&
      showStatusMenu &&
      !statusMenu.current.contains(e.target)
    ) {
      setShowStatusMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  dayjs.extend(relativeTime);

  const fetchConnectors = (from, size) => {
    dispatch(setShowCommonLoader(true));
    let params = { from: from, size: size };
    try {
      getConnectors(params)
        .then((res) => {
          if (res.response.data) {
            setTotalListLength(res.response?.size);
            setConnectorList((prev) => [...prev, ...res.response?.data]);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      dispatch(setShowCommonLoader(false));
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const n = urlParams.get("new");
    if (n) navigateToConnectors();
  }, []);

  // const onDatasourceModalClose = (reloadData) => {
  //   setShowConnectModal(false);
  //   if (reloadData) fetchFileUploads();
  // };

  const navigateToConnectors = () => {
    navigate("connectors");
  };

  const navigateToStreams = (connector) => {
    if (!connector.id) {
      Notify({
        type: "error",
        heading: "Invalid Connector ID.",
        message: "Failed to retrieve connector streams.",
      });
      return;
    }
    if (connector?.id === "csv") {
      navigate("/data/csv");
    } else {
      navigate(`/data/streams/${connector.id}`);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          if (showSegmentSideMenu) {
            setShowSegmentSideMenu(false);
          }
        }}
      >
        <div className="-mt-10">
          <div className="flex justify-between mb-8">
            <div className="flex gap-4">
              {/* <div className="relative">
                    <button
                      className="inline-flex items-center px-4 py-3 text-sm font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label={`${getPageName()}-list_source-filter_click`}
                      data-event-action="filter_click"
                      type="button"
                      onClick={() => setShowSourceMenu(!showSourceMenu)}
                    >
                      Source{" "}
                      <svg
                        className="w-4 h-4 ml-2"
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>

                    {showSourceMenu && (
                      <div
                        ref={sourceMenu}
                        className="absolute mt-2 z-10 bg-white rounded-lg shadow w-40"
                      >
                        <ul
                          className="p-2 text-sm text-gray-700 triggerGA"
                          data-event-category={`${getPageName()}-list-page`}
                          data-event-label={`${getPageName()}-list_source-option_click`}
                          data-event-action="filter_click"
                        >
                          <li>
                            <div className="flex items-center p-3 rounded hover:bg-gray-100">
                              <input
                                id="csv"
                                type="checkbox"
                                value="csv"
                                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                              />
                              <label
                                htmlFor="csv"
                                className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                              >
                                CSV Data
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="flex items-center p-3 rounded hover:bg-gray-100">
                              <input
                                id="sftp"
                                type="checkbox"
                                value="sftp"
                                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                              />
                              <label
                                htmlFor="sftp"
                                className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                              >
                                SFTP
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="flex items-center p-3 rounded hover:bg-gray-100">
                              <input
                                id="oracle"
                                type="checkbox"
                                value="oracle"
                                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                              />
                              <label
                                htmlFor="oracle"
                                className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                              >
                                Oracle
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div> */}

              {/* <div className="relative">
                    <button
                      className="inline-flex items-center px-4 py-3 text-sm font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label={`${getPageName()}-list_status-filter_click`}
                      data-event-action="filter_click"
                      type="button"
                      onClick={() => setShowStatusMenu(!showStatusMenu)}
                    >
                      Status{" "}
                      <svg
                        className="w-4 h-4 ml-2"
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>

                    {showStatusMenu && (
                      <div
                        ref={statusMenu}
                        className="absolute mt-2 z-10 bg-white rounded-lg shadow w-40"
                      >
                        <ul
                          className="p-2 text-sm text-gray-700 triggerGA"
                          data-event-category={`${getPageName()}-list-page`}
                          data-event-label={`${getPageName()}-list_status-filter-option_click`}
                          data-event-action="filter_click"
                        >
                          <li>
                            <div className="flex items-center p-3 rounded hover:bg-gray-100">
                              <input
                                id="active"
                                type="checkbox"
                                value="active"
                                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                              />
                              <label
                                htmlFor="active"
                                className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                              >
                                Active
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="flex items-center p-3 rounded hover:bg-gray-100">
                              <input
                                id="success"
                                type="checkbox"
                                value="success"
                                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                              />
                              <label
                                htmlFor="success"
                                className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                              >
                                Success
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="flex items-center p-3 rounded hover:bg-gray-100">
                              <input
                                id="broken"
                                type="checkbox"
                                value="broken"
                                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                              />
                              <label
                                htmlFor="broken"
                                className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                              >
                                Broken
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="flex items-center p-3 rounded hover:bg-gray-100">
                              <input
                                id="delayed"
                                type="checkbox"
                                value="delayed"
                                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                              />
                              <label
                                htmlFor="delayed"
                                className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                              >
                                Delayed
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="flex items-center p-3 rounded hover:bg-gray-100">
                              <input
                                id="incomplete"
                                type="checkbox"
                                value="incomplete"
                                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                              />
                              <label
                                htmlFor="incomplete"
                                className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                              >
                                Incomplete
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div> */}
            </div>

            <div className="group">
              <button
                className={`py-3 px-6 text-white font-semibold border rounded-lg bg-blue-75 cursor-pointer ${
                  tenantContext.productList["dataupload_create_policy"] &&
                  "hover:bg-green-75"
                } relative`}
                onClick={() => navigateToConnectors()}
                disabled={
                  !tenantContext.productList["dataupload_create_policy"]
                }
              >
                Connect data source
                {!tenantContext.productList["dataupload_create_policy"] && (
                  <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[190px] top-[-12px] z-20">
                    {MESSAGE}
                  </div>
                )}
              </button>
            </div>
          </div>
          <div>
            <table className="min-w-full">
              <thead>
                <tr>
                  {["Name", "Status", "Last synced"].map((item, index) => (
                    <th
                      key={index}
                      className={`${
                        index === 0 ? "w-30p" : "w-22p"
                      } py-3 border-b border-gray-100 text-left text-gray-700`}
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              {pageNumber > 0 && connectorList?.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <div className="w-full p-24">
                        <div className="flex flex-col w-full bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                          <div className="mt-2">
                            No data to show. Go to previous page.
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {connectorList.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-100 text-gray-70 cursor-pointer hover:bg-gray-25"
                      onClick={() => navigateToStreams(item)}
                    >
                      <td className="py-4">
                        <div className="flex items-center gap-2">
                          <div className="border rounded-full p-2">
                            {/* {item.type === "csv" ? <Csv /> : <Sftp />} */}
                            <Csv />
                          </div>
                          <div>{item?.name}</div>
                        </div>
                      </td>
                      <td className="-m-4">
                        <button
                          className={`first-letter:capitalize ${colorCode(
                            item?.status
                          )} font-medium text-[12px] py-0.5 px-2 rounded-2xl`}
                        >
                          {item?.status}
                        </button>
                      </td>
                      <td>{""}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          {connectorList.length !== 0 && (
            <div className="mt-8">
              <PaginationComp
                onPageChange={(from, size) => {
                  fetchConnectors(from, size);
                }}
                reset
                totalCount={totalListLength}
              />
            </div>
          )}
        </div>
        {/* {showConnectModal && (
              <ConnectDataSourceModal
                navigate={navigate}
                closeModal={(status) => onDatasourceModalClose(status)}
                switchModal={(value) => {
                  switchModal(value);
                }}
              />
            )} */}
        {/* {planUpgradeModalStatus && (
              <PlanUpgradeModal
                navigate={navigate}
                close={() => switchModal("data")}
              />
            )} */}
        {/* {reachCustomerSupportModalStatus && (
              <ReachCustomerSuccessModal close={() => switchModal("data")} />
            )} */}
      </div>
    </>
  );
};

export default Data;
