import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { getStreams } from "../../api/dataServices";
import {
  colorCode,
  formatNumbers,
} from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import MySql from "../../common/assets/icons/MySql";
// import { sync } from "../../common/assets/icons";
import { Search } from "react-feather";
import {
  Budget,
  Spent,
  Type,
} from "../../../../engagements/src/assets/icons/icons";

const TABS = { LOGS: "Logs", SETUP: "Setup" };

const DataStreams = (props) => {
  let { id, setConnectorName, dispatch, setShowCommonLoader } = props;
  const [connectionDetails, setConnectionDetails] = useState(null);
  const [currentTab, setCurrentTab] = useState(TABS.LOGS);

  const fetchStreams = () => {
    dispatch(setShowCommonLoader(true));
    try {
      getStreams(id)
        .then((res) => {
          const { response, error } = res;
          if (!error && response) {
            setConnectionDetails(response);
            setConnectorName(response.name);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      dispatch(setShowCommonLoader(false));
    }
  };

  useEffect(() => {
    if (props.id) {
      fetchStreams();
    }
  }, []);

  return (
    <div>
      <div>
        <MySql />
        <div className="mt-8 flex gap-4 items-center">
          <div className="text-lg text-gray-700 font-semibold capitalize">
            {connectionDetails?.name}
          </div>
          <button
            className={`first-letter:capitalize ${colorCode(
              "active"
            )} font-semibold text-[12px] py-1 px-2 rounded-2xl`}
          >
            Active
          </button>
        </div>
        {/* <div className="text-gray-500 mt-2.5">23k Profiles</div> */}

        <div className="text-gray-500 mt-4 flex justify-between items-center">
          <div>
            Last data sync was{" "}
            <span className="text-gray-700">{`${connectionDetails?.lastSync} min ago.`}</span>
          </div>

          {/* <div>
            <div className="flex gap-2 items-center">
              Sync now
              <img src={sync} alt="" />
            </div>
          </div> */}
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-10">
        <div className="flex gap-6">
          <div
            onClick={() => setCurrentTab(TABS.LOGS)}
            className={`leading-6 cursor-pointer h-[34px] items-center ${
              currentTab === TABS.LOGS
                ? "border-b-2 border-b-gray-200 text-gray-200 font-semibold"
                : "text-gray-500"
            }`}
          >
            {TABS.LOGS}
          </div>
          <div
            onClick={() => setCurrentTab(TABS.SETUP)}
            className={`leading-6 cursor-pointer h-[34px] items-center ${
              currentTab === TABS.SETUP
                ? "border-b-2 border-b-gray-200 text-gray-200 font-semibold"
                : "text-gray-500"
            }`}
          >
            {TABS.SETUP}
          </div>
        </div>

        <div className="mt-6">
          {currentTab === TABS.LOGS && (
            <div className="relative">
              <div className="w-96 flex border border-slate-300 rounded-lg mb-6">
                <input
                  type="text"
                  placeholder="Search logs"
                  className="w-full pt-0.5 pr-7 ml-3 focus:border-none font-normal focus:outline-none "
                  onChange={(e) => {}}
                />
                <div className="w-10 h-10 text-gray-400">
                  <Search className="text-xl m-3" size="16" />
                </div>
              </div>

              <table className="min-w-full">
                <thead>
                  <tr>
                    {["Name", "Time Processed", "Status", "Profiles"].map(
                      (item, index) => (
                        <th
                          key={index}
                          className={
                            "py-3 border-b border-gray-100 text-left text-gray-700"
                          }
                        >
                          {item}
                        </th>
                      )
                    )}
                  </tr>
                </thead>

                {connectionDetails?.streams?.length === 0 ? (
                  <div className="w-full p-24 absolute">
                    <div className="flex w-full flex-col bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                      <div className="mt-2">No Data to Show.</div>
                    </div>
                  </div>
                ) : (
                  <tbody>
                    {connectionDetails?.streams?.map((stream, index) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-100 text-gray-70 cursor-pointer"
                        >
                          <td className="py-6 text-base leading-6 capitalize">
                            {stream?.name}
                          </td>

                          <td className="text-base leading-6 capitalize">
                            {dayjs(Number(stream?.timeProcessed)).format(
                              "MMMM D, YYYY h:mm A"
                            )}
                          </td>
                          <td className="text-base leading-6 first-letter:capitalize">
                            <button
                              className={`first-letter:capitalize ${colorCode(
                                stream?.status
                              )} font-semibold text-[12px] py-1 px-2 rounded-2xl`}
                            >
                              {stream?.status}
                            </button>
                          </td>
                          <td className="text-base leading-6 first-letter:capitalize">
                            {formatNumbers(stream?.recordsCount)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          )}

          {currentTab === TABS.SETUP && (
            <div>
              <div className="mt-10">
                <div className="flex gap-4">
                  <div className="w-40 text-base font-semibold text-gray-70">
                    Connection details
                  </div>
                  <div className="grow h-1 border-t mt-3 border-[#EBEFF3]"></div>
                </div>

                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 text-gray-70">
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={Budget} alt="" />
                      </div>
                      <div className="ml-6">
                        <span className="font-semibold leading-6 whitespace-no-wrap">
                          Bucket name
                        </span>
                        <p className="pt-1 font-normal text-sm whitespace-no-wrap">
                          {connectionDetails?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={Spent} alt="" />
                      </div>
                      <div className="ml-6">
                        <span className="font-semibold leading-6 whitespace-no-wrap">
                          Connected by
                        </span>
                        <p className="pt-1 font-normal text-sm whitespace-no-wrap first-letter:capitalize">
                          {connectionDetails?.createdBy?.name},{" "}
                          {connectionDetails?.createdBy?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={Type} alt="" />
                      </div>
                      <div className="ml-6">
                        <span className="font-semibold leading-6 whitespace-no-wrap">
                          Connected on
                        </span>
                        <p className="pt-1 font-normal text-sm whitespace-no-wrap break-all">
                          {dayjs(
                            Number(connectionDetails?.streams[0]?.timeProcessed)
                          ).format("MMMM D, YYYY h:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-14">
                <div className="flex gap-4">
                  <div className="w-40 text-base font-semibold text-gray-70">
                    Settings
                  </div>
                  <div className="grow h-1 border-t mt-3 border-[#EBEFF3]"></div>
                </div>

                <div className="text-gray-200 py-4 font-semibold">
                  Set sync frequency
                </div>
                <div className="text-gray-500">
                  Set how often you need to sync this datasource with updated
                  profiles
                </div>

                <div className="flex items-center gap-4 mt-4">
                  {[
                    "5 min",
                    "15 min",
                    "30 min",
                    "1h",
                    "2h",
                    "3h",
                    "6h",
                    "8h",
                    "12h",
                    "24h",
                  ].map((item) => (
                    <div
                      key={item}
                      className={`${
                        item === "2h"
                          ? "py-5 px-8 bg-blue-75 hover:bg-primary-green text-white cursor-pointer"
                          : "py-3 px-6 text-gray-70 cursor-not-allowed"
                      } border rounded-lg font-semibold`}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataStreams;
