import { useCallback } from "react";
import BackButton from "../assets/icons/BackButton";
import GreenTick from "../assets/icons/GreenTick";
import UpgradePlan from "../assets/icons/UpgradePlan";

const PlanUpgradeModal = ({ navigate, close }) => {
  const navigateToPricing = useCallback(
    () => navigate("/pricing", { replace: true }),
    [navigate]
  );
  return (
    <div className="absolute w-screen h-screen left-0 top-0 bg-gray-950/[.4] flex justify-center items-center">
      <div className="w-[490px] p-6 bg-white rounded-xl">
        <div
          className="flex gap-2 items-center cursor-pointer text-sm font-semibold"
          onClick={() => {
            close();
          }}
        >
          <BackButton />
          Back
        </div>
        <div className="mb-4 bg-green-200 border border-gray-100 h-[149px] flex justify-between mt-4">
          <div className="px-5 pt-14 pb-9 text-green-250 text-2xl font-semibold leading-8">
            <div>Upgrade to a</div>
            <div>higher plan.</div>
          </div>
          <div className="flex items-end">
            <UpgradePlan />
          </div>
        </div>
        <div className="mt-6 text-slate-600 text-sm font-semibold leading-5">
          Upgrade to a higher plan to keep your data flowing in from various
          data sources.
        </div>
        <div className="mt-6">
          {[
            "Connect from a wider range of sources",
            "Enable deeper customer insights",
            "Ensure customer data is always up-to-date",
            "Utilize advanced analytics capabilities",
          ].map((value) => {
            return (
              <div
                key={value}
                className="flex gap-4 items-center text-gray-500 text-sm leading-6 mt-1"
              >
                <div>
                  <GreenTick />
                </div>
                <div>{value}</div>
              </div>
            );
          })}
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <div
            onClick={() => {
              close();
            }}
            className="py-3 px-5 text-sm leading-4 bg-white font-semibold border border-gray-40 rounded-md text-gray-70 cursor-pointer"
          >
            Maybe later
          </div>
          <div
            onClick={() => {
              navigateToPricing();
            }}
            className="py-3 px-5 text-sm leading-4 font-semibold border border-blue-75 rounded-md bg-blue-75 text-white cursor-pointer"
          >
            Upgrade plan
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanUpgradeModal;
