const CustomerSupport = () => {
  return (
    <svg
      width="212"
      height="125"
      viewBox="0 0 212 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.24">
        <mask id="path-2-inside-1_80980_187904" fill="white">
          <path d="M50 56C50 49.3726 58.7304 44 69.5 44Lnan nanL69.5 44C80.2696 44 89 49.3726 89 56V135C89 141.627 80.2696 147 69.5 147Lnan nanL69.5 147C58.7304 147 50 141.627 50 135V56Z" />
        </mask>
        <path
          d="M88 56V135H90V56H88ZM51 135V56H49V135H51ZM69.5 146C64.2698 146 59.5826 144.693 56.2355 142.634C52.8722 140.564 51 137.837 51 135H49C49 138.79 51.493 142.064 55.1873 144.337C58.8978 146.62 63.9606 148 69.5 148V146ZM88 135C88 137.837 86.1278 140.564 82.7645 142.634C79.4174 144.693 74.7302 146 69.5 146V148C75.0394 148 80.1022 146.62 83.8127 144.337C87.507 142.064 90 138.79 90 135H88ZM69.5 45C74.7302 45 79.4174 46.3066 82.7645 48.3664C86.1278 50.4361 88 53.1631 88 56H90C90 52.2095 87.507 48.9365 83.8127 46.6631C80.1022 44.3797 75.0394 43 69.5 43V45ZM69.5 43C63.9606 43 58.8978 44.3797 55.1873 46.6631C51.493 48.9365 49 52.2095 49 56H51C51 53.1631 52.8722 50.4361 56.2355 48.3664C59.5826 46.3066 64.2698 45 69.5 45V43Z"
          fill="#9DD9BB"
          mask="url(#path-2-inside-1_80980_187904)"
        />
        <mask id="path-4-inside-2_80980_187904" fill="white">
          <path d="M85.5 33C85.5 26.3726 93.7827 21 104 21Lnan nanL104 21C114.217 21 122.5 26.3726 122.5 33V135C122.5 141.627 114.217 147 104 147Lnan nanL104 147C93.7827 147 85.5 141.627 85.5 135V33Z" />
        </mask>
        <path
          d="M121.5 33V135H123.5V33H121.5ZM86.5 135V33H84.5V135H86.5ZM104 146C99.0558 146 94.626 144.698 91.4627 142.646C88.2897 140.588 86.5 137.862 86.5 135H84.5C84.5 138.765 86.8517 142.039 90.3743 144.324C93.9067 146.616 98.7269 148 104 148V146ZM121.5 135C121.5 137.862 119.71 140.588 116.537 142.646C113.374 144.698 108.944 146 104 146V148C109.273 148 114.093 146.616 117.626 144.324C121.148 142.039 123.5 138.765 123.5 135H121.5ZM104 22C108.944 22 113.374 23.3018 116.537 25.3537C119.71 27.4119 121.5 30.1376 121.5 33H123.5C123.5 29.235 121.148 25.9607 117.626 23.6758C114.093 21.3845 109.273 20 104 20V22ZM104 20C98.7269 20 93.9067 21.3845 90.3743 23.6758C86.8517 25.9607 84.5 29.235 84.5 33H86.5C86.5 30.1376 88.2897 27.4119 91.4627 25.3537C94.626 23.3018 99.0558 22 104 22V20Z"
          fill="#FCB415"
          mask="url(#path-4-inside-2_80980_187904)"
        />
        <mask id="path-6-inside-3_80980_187904" fill="white">
          <path d="M119 81C119 74.3726 127.954 69 139 69Lnan nanL139 69C150.046 69 159 74.3726 159 81V135C159 141.627 150.046 147 139 147Lnan nanL139 147C127.954 147 119 141.627 119 135V81Z" />
        </mask>
        <path
          d="M158 81V135H160V81H158ZM120 135V81H118V135H120ZM139 146C133.627 146 128.812 144.691 125.372 142.628C121.913 140.552 120 137.824 120 135H118C118 138.803 120.564 142.075 124.343 144.343C128.143 146.622 133.327 148 139 148V146ZM158 135C158 137.824 156.087 140.552 152.628 142.628C149.188 144.691 144.373 146 139 146V148C144.673 148 149.857 146.622 153.657 144.343C157.436 142.075 160 138.803 160 135H158ZM139 70C144.373 70 149.188 71.3087 152.628 73.3722C156.087 75.4477 158 78.1756 158 81H160C160 77.197 157.436 73.9249 153.657 71.6572C149.857 69.3776 144.673 68 139 68V70ZM139 68C133.327 68 128.143 69.3776 124.343 71.6572C120.564 73.9249 118 77.197 118 81H120C120 78.1756 121.913 75.4477 125.372 73.3722C128.812 71.3087 133.627 70 139 70V68Z"
          fill="#53AC80"
          mask="url(#path-6-inside-3_80980_187904)"
        />
      </g>
      <path
        d="M177.665 80.5265C177.114 87.488 171.024 92.6846 164.062 92.1335C157.101 91.5824 151.904 85.4922 152.455 78.5307C153.006 71.5692 159.097 66.3726 166.058 66.9237C173.02 67.4748 178.216 73.565 177.665 80.5265Z"
        fill="#53AC80"
      />
      <g opacity="0.6" clip-path="url(#clip0_80980_187904)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M165.061 74.5156C162.294 74.5156 160.051 76.7589 160.051 79.526C160.051 80.3283 160.239 81.0854 160.573 81.7567C160.708 82.0261 160.759 82.3483 160.675 82.6647L160.352 83.8705C160.293 84.0919 160.495 84.2945 160.717 84.2353L161.923 83.9127C162.239 83.828 162.561 83.8797 162.831 84.0139C163.502 84.3482 164.259 84.5365 165.061 84.5365C167.828 84.5365 170.072 82.2932 170.072 79.526C170.072 76.7589 167.828 74.5156 165.061 74.5156ZM159.238 79.526C159.238 76.3101 161.845 73.7031 165.061 73.7031C168.277 73.7031 170.884 76.3101 170.884 79.526C170.884 82.742 168.277 85.349 165.061 85.349C164.13 85.349 163.25 85.1303 162.468 84.7412C162.352 84.6831 162.233 84.6707 162.133 84.6975L160.927 85.0202C160.101 85.241 159.346 84.4859 159.567 83.6605L159.89 82.4547C159.916 82.3546 159.904 82.2355 159.846 82.1189C159.457 81.3376 159.238 80.4568 159.238 79.526ZM162.488 78.7135C162.488 78.4892 162.67 78.3073 162.895 78.3073H167.228C167.452 78.3073 167.634 78.4892 167.634 78.7135C167.634 78.9379 167.452 79.1198 167.228 79.1198H162.895C162.67 79.1198 162.488 78.9379 162.488 78.7135ZM162.488 80.6094C162.488 80.385 162.67 80.2031 162.895 80.2031H165.874C166.098 80.2031 166.28 80.385 166.28 80.6094C166.28 80.8337 166.098 81.0156 165.874 81.0156H162.895C162.67 81.0156 162.488 80.8337 162.488 80.6094Z"
          fill="white"
        />
      </g>
      <path
        d="M182.054 78.5446C182.1 77.9655 182.606 77.5333 183.185 77.5792L202.058 79.0744C202.637 79.1203 203.07 79.6269 203.024 80.206C202.978 80.7851 202.471 81.2173 201.892 81.1714L183.019 79.6762C182.44 79.6303 182.008 79.1237 182.054 78.5446Z"
        fill="#53AC80"
      />
      <path
        d="M182.042 83.0407C182.088 82.4616 182.594 82.0294 183.173 82.0753L194.707 82.989C195.286 83.0349 195.718 83.5415 195.672 84.1206C195.627 84.6997 195.12 85.1319 194.541 85.086L183.007 84.1723C182.428 84.1264 181.996 83.6198 182.042 83.0407Z"
        fill="#53AC80"
      />
      <path
        d="M136.646 26.8859C136.094 33.8474 130.004 39.044 123.043 38.4929C116.081 37.9418 110.885 31.8516 111.436 24.8901C111.987 17.9286 118.077 12.7319 125.039 13.2831C132 13.8342 137.197 19.9244 136.646 26.8859Z"
        fill="#FCB416"
      />
      <g opacity="0.6" clip-path="url(#clip1_80980_187904)">
        <path
          d="M119.836 24.2422L123.898 26.6797L127.961 24.2422"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M116.992 24.2422H119.836"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.867 25.8672L120.242 25.8672"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M116.992 27.4922H119.836"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M119.836 24.2422V23.8359C119.836 22.9422 120.567 22.2109 121.461 22.2109H126.336C127.23 22.2109 127.961 22.9422 127.961 23.8359V27.8984C127.961 28.7922 127.23 29.5234 126.336 29.5234H121.461C120.567 29.5234 119.836 28.7922 119.836 27.8984V27.4922"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        d="M141.034 23.8532C141.08 23.2741 141.587 22.8419 142.166 22.8878L161.039 24.383C161.618 24.4289 162.05 24.9355 162.004 25.5146C161.958 26.0937 161.452 26.5259 160.873 26.48L142 24.9848C141.42 24.9389 140.988 24.4323 141.034 23.8532Z"
        fill="#FCB416"
      />
      <path
        d="M141.022 28.3493C141.068 27.7702 141.575 27.338 142.154 27.3839L153.687 28.2976C154.267 28.3435 154.699 28.8501 154.653 29.4292C154.607 30.0083 154.1 30.4405 153.521 30.3946L141.988 29.4809C141.409 29.435 140.977 28.9284 141.022 28.3493Z"
        fill="#FCB416"
      />
      <path
        d="M49.3467 83.6827C48.7956 90.6442 42.7054 95.8409 35.7439 95.2898C28.7824 94.7386 23.5857 88.6484 24.1369 81.6869C24.688 74.7254 30.7782 69.5288 37.7397 70.0799C44.7012 70.6311 49.8978 76.7212 49.3467 83.6827Z"
        fill="#9DD9BB"
      />
      <path
        opacity="0.6"
        d="M38.1311 79.628C38.5406 79.7079 38.9471 79.8885 39.2422 80.1836C39.5372 80.4786 39.7179 80.8852 39.7977 81.2947M38.4089 77.6836C39.2597 77.7781 40.0254 78.1897 40.6311 78.7947C41.2368 79.3997 41.6466 80.1662 41.7422 81.0169M40.6457 87.6797C41.2267 87.7321 41.7442 87.2515 41.7419 86.676V85.1706C41.748 84.9245 41.6632 84.6848 41.5038 84.497C41.0541 83.9673 39.5119 83.6285 38.9006 83.7586C38.424 83.86 38.0906 84.3385 37.7647 84.6638C37.1901 84.3377 36.6606 83.9437 36.1866 83.4923M34.7479 81.6529C35.0738 81.3276 35.5532 80.9949 35.6549 80.5193C35.785 79.9101 35.4467 78.3778 34.9207 77.9263C34.7357 77.7675 34.4991 77.6812 34.2551 77.6836H32.7467C32.172 77.6842 31.6938 78.1997 31.7461 78.7776C31.7428 82.9878 34.5207 86.4435 38.4089 87.4099"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.0829445 77.4938C0.128823 76.9147 0.635445 76.4825 1.21451 76.5284L20.0875 78.0237C20.6666 78.0695 21.0988 78.5762 21.0529 79.1552C21.0071 79.7343 20.5004 80.1665 19.9214 80.1207L1.04837 78.6254C0.469303 78.5795 0.0370656 78.0729 0.0829445 77.4938Z"
        fill="#9DD9BB"
      />
      <path
        d="M7.44623 81.9899C7.4921 81.4108 7.99873 80.9786 8.5778 81.0245L20.1113 81.9383C20.6904 81.9841 21.1226 82.4908 21.0767 83.0698C21.0308 83.6489 20.5242 84.0811 19.9451 84.0353L8.41165 83.1215C7.83258 83.0756 7.40035 82.569 7.44623 81.9899Z"
        fill="#9DD9BB"
      />
      <path
        d="M113.518 89.8477C117.49 85.6621 120.02 79.7494 120.02 74.1051C120.02 63.8475 111.709 55.5312 101.448 55.5312C91.1904 55.5312 82.8767 63.8449 82.8767 74.1051C82.8767 76.6422 83.4098 79.2235 84.3347 81.6876C86.6166 86.829 91.0966 90.8223 96.7699 92.2727C97.8943 90.884 99.5894 90.0517 101.448 90.0517C104.782 90.0517 107.496 92.7636 107.496 96.0984C107.496 99.4337 104.782 102.143 101.448 102.143C99.0745 102.143 96.9751 100.759 95.9903 98.6913C91.1136 97.6181 86.8365 95.1701 83.5129 91.81C76.1953 94.9367 71.4492 99.9151 71.4492 105.536C71.4492 115.005 131.449 115.005 131.449 105.536C131.449 98.5212 124.073 92.5016 113.518 89.8477Z"
        fill="#16A34A"
      />
      <path
        d="M97.6169 96.8239C97.9598 98.6327 99.5412 100.001 101.445 100.001C103.602 100.001 105.35 98.2517 105.35 96.097C105.35 93.9423 103.602 92.1933 101.448 92.1933C99.7901 92.1933 98.3837 93.2297 97.8164 94.6872C88.0068 92.9616 80.5262 84.3977 80.5262 74.1014C80.5262 62.5647 89.9131 53.1782 101.447 53.1782C112.987 53.1782 122.371 62.5647 122.371 74.1014H124.512C124.512 61.3842 114.165 51.0352 101.447 51.0352C88.73 51.0352 78.3828 61.3819 78.3828 74.1014C78.3809 85.5142 86.7209 94.9903 97.6169 96.8239Z"
        fill="#16A34A"
      />
      <defs>
        <clipPath id="clip0_80980_187904">
          <rect
            width="13"
            height="13"
            fill="white"
            transform="translate(158.561 73.0273)"
          />
        </clipPath>
        <clipPath id="clip1_80980_187904">
          <rect
            width="13"
            height="13"
            fill="white"
            transform="translate(115.367 19.3672)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomerSupport;
