const SettingsBlue = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15698 3.26448C7.06057 3.36284 7.00657 3.49508 7.00657 3.63281C7.00657 3.77054 7.06057 3.90277 7.15698 4.00113L7.99887 4.84302C8.09722 4.93943 8.22946 4.99343 8.36719 4.99343C8.50492 4.99343 8.63716 4.93943 8.73552 4.84302L10.7192 2.85933C10.9838 3.44401 11.0639 4.09544 10.9489 4.72681C10.8338 5.35818 10.5291 5.93949 10.0753 6.39329C9.62152 6.84708 9.0402 7.1518 8.40884 7.26684C7.77747 7.38187 7.12604 7.30176 6.54135 7.03718L2.90546 10.6731C2.69613 10.8824 2.41223 11 2.11619 11C1.82016 11 1.53625 10.8824 1.32693 10.6731C1.1176 10.4637 1 10.1798 1 9.88381C1 9.58777 1.1176 9.30386 1.32693 9.09454L4.96282 5.45865C4.69824 4.87396 4.61813 4.22253 4.73316 3.59116C4.8482 2.9598 5.15292 2.37848 5.60671 1.92469C6.06051 1.47089 6.64182 1.16617 7.27319 1.05114C7.90456 0.9361 8.55599 1.01621 9.14067 1.28079L7.16224 3.25922L7.15698 3.26448Z"
        stroke="#0B59A0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SettingsBlue;
