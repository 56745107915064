const GreenTick = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6783 0.761449L10.6861 0.769327L10.6943 0.77685C10.7197 0.80026 10.7399 0.828673 10.7538 0.860297C10.7676 0.891922 10.7748 0.926074 10.7748 0.960599C10.7748 0.995124 10.7676 1.02928 10.7538 1.0609C10.7399 1.09252 10.7197 1.12094 10.6943 1.14435L10.6869 1.15118L10.6798 1.1583L4.55976 7.2783L4.55976 7.27829L4.55832 7.27975C4.53508 7.30318 4.50743 7.32178 4.47696 7.33447C4.4465 7.34716 4.41382 7.3537 4.38082 7.3537C4.34781 7.3537 4.31514 7.34716 4.28467 7.33447C4.25421 7.32178 4.22656 7.30318 4.20332 7.27975L4.20187 7.2783L1.32187 4.3983L1.32192 4.39824L1.3157 4.39223C1.29054 4.36792 1.2704 4.33889 1.25646 4.30681C1.24251 4.27471 1.23502 4.24019 1.23441 4.20521C1.23381 4.17022 1.2401 4.13546 1.25293 4.10291C1.26576 4.07036 1.28487 4.04065 1.30918 4.01548C1.33349 3.99032 1.36252 3.97019 1.39461 3.95624C1.4267 3.94229 1.46122 3.9348 1.49621 3.9342C1.53119 3.93359 1.56595 3.93988 1.59851 3.95271C1.62997 3.96511 1.65878 3.98339 1.6834 4.00655L4.0267 6.35734L4.38049 6.71226L4.73461 6.35767L10.3221 0.762667L10.3233 0.761449C10.3466 0.738017 10.3742 0.719418 10.4047 0.706727C10.4351 0.694035 10.4678 0.6875 10.5008 0.6875C10.5338 0.6875 10.5665 0.694035 10.597 0.706727C10.6274 0.719418 10.6551 0.738015 10.6783 0.761449Z"
        fill="black"
        stroke="#008138"
      />
    </svg>
  );
};

export default GreenTick;
