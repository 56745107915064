import { useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { getDataList } from "../../api/dataServices";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import DataSources from "../../common/assets/icons/DataSources";
import PaginationComp from "../../../../engagements/src/common/components/pagination";
import {
  Action,
  EngageSegments,
} from "../../../../audience/src/common/assets/icons";
import { colorCode } from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import Csv from "../../common/assets/icons/Csv";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { postAudienceFromCampaign } from "../../../../marketing-cloud-main/src/common/apis/audience";
import ConnectDataSourceModal from "../../common/components/ConnectDataSourceModal";

interface DataItem {
  segment_id: string;
  client_id: string;
  user_id: string;
  file_name: string;
  total_profiles: number;
  upload_done_time: string;
  upload_start_time: string;
}

const sortByTimeDesc = (data: DataItem[]): DataItem[] => {
  return data.sort(function (a, b) {
    const dateA = new Date(a.upload_done_time);
    const dateB = new Date(b.upload_done_time);

    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
};

const CsvUploads = (props) => {
  let { dispatch, navigate, setShowCommonLoader, tenantContext } = props;
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [totalListLength, setTotalListLength] = useState(0);
  const [showSegmentSideMenu, setShowSegmentSideMenu] = useState(false);
  const [segmentId, setSegmentId] = useState(null);
  const [highlight, setHighlight] = useState(null);
  const [highlightId, setHighlightId] = useState("");

  const segmentMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      segmentMenu.current &&
      showSegmentSideMenu &&
      !segmentMenu.current.contains(e.target)
    ) {
      setShowSegmentSideMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  useEffect(() => {
    checkQueryString();
    fetchFileUploads(0, 10);
  }, [highlightId]);

  dayjs.extend(relativeTime);

  const fetchFileUploads = (from, size) => {
    dispatch(setShowCommonLoader(true));
    let params = { from: from, size: size };
    try {
      getDataList(params)
        .then((res) => {
          if (res) {
            setTotalListLength(res?.total);
            setDataList(sortByTimeDesc(res?.data));
            if (highlightId) {
              setHighlight(highlightId);
            }
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      dispatch(setShowCommonLoader(false));
    }
  };

  const checkQueryString = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const h = urlParams.get("h");
    if (h) setHighlight(h);
  };

  const uploadCsv = () => {
    setShowConnectModal(true);
  };

  const onDatasourceModalClose = (reloadData) => {
    setShowConnectModal(false);
    if (reloadData) true; //fetchFileUploads();
  };

  const handleSideMenu = (item) => {
    setShowSegmentSideMenu(true);
    setSegmentId(item);
  };

  const createAudience = (segment) => {
    dispatch(setShowCommonLoader(true));
    postAudienceFromCampaign({
      segment: segment.segment_id,
      name: segment.file_name,
      size: segment.distinct_profiles,
      description: "Data",
    })
      .then((audience: any) => {
        dispatch(setShowCommonLoader(false));
        if (!audience.error)
          navigate(`/audience?h=${audience.data.audience_id}`);
      })
      .catch((err) => {
        dispatch(setShowCommonLoader(false));
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        console.log(err);
      });
  };

  return (
    <div>
      {dataList.length === 0 ? (
        <div className="flex items-center justify-between w-[64rem] mx-auto mt-36">
          <div className="w-128">
            <div className="text-gray-800 text-4xl font-semibold leading-[2.75rem] mb-4">
              It looks like you haven't added any data yet.
            </div>
            <div className="text-gray-70 text-base mb-6">
              Connect your customer data from all other platforms and apps, and
              add them as sources.
            </div>
            <div className="flex gap-4">
              <div className="group">
                <button
                  className={`py-3 px-6 text-white text-sm font-semibold border rounded-lg bg-blue-75 ${
                    tenantContext.productList["dataupload_create_policy"] &&
                    "hover:bg-green-75"
                  } relative`}
                  onClick={uploadCsv}
                  disabled={
                    !tenantContext.productList["dataupload_create_policy"]
                  }
                >
                  Import new file
                  {!tenantContext.productList["dataupload_create_policy"] && (
                    <div className="absolute hidden group-hover:flex bg-black p-3 text-xs text-white rounded-lg w-[300px] right-[-306px] top-[-12px] z-20">
                      {MESSAGE}
                    </div>
                  )}
                </button>
              </div>
              <button className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500">
                Learn more
              </button>
            </div>
          </div>
          <DataSources />
        </div>
      ) : (
        <>
          <div>
            <div className="flex justify-between mb-8">
              <div className="flex gap-4 items-center">
                <div className="border rounded-full py-2.5 px-3 scale-125">
                  <Csv />
                </div>
                <div className="font-semibold text-xl">CSV Uploads</div>
              </div>

              <div className="group">
                <button
                  className={`py-3 px-6 text-white font-semibold border rounded-lg bg-blue-75 cursor-pointer ${
                    tenantContext.productList["dataupload_create_policy"] &&
                    "hover:bg-green-75"
                  } relative`}
                  onClick={uploadCsv}
                  disabled={
                    !tenantContext.productList["dataupload_create_policy"]
                  }
                >
                  Import new file
                  {!tenantContext.productList["dataupload_create_policy"] && (
                    <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[190px] top-[-12px] z-20">
                      {MESSAGE}
                    </div>
                  )}
                </button>
              </div>
            </div>
            <div>
              <table className="min-w-full">
                <thead>
                  <tr>
                    {["File", "Profiles", "Status", "Last synced"].map(
                      (item, index) => (
                        <th
                          key={index}
                          className={`${
                            index === 0 ? "w-30p" : "w-22p"
                          } py-3 border-b border-gray-100 text-left text-gray-700`}
                        >
                          {item}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                {dataList?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <div className="w-full p-24">
                          <div className="flex flex-col w-full bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                            <div className="mt-2">
                              No data to show. Go to previous page.
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {dataList.map((item, index) => (
                      <tr
                        key={index}
                        className={`border-b border-gray-100 text-gray-70 cursor-pointer hover:bg-gray-25 ${
                          highlight === item.segment_id && "animate-blink-2"
                        }`}
                      >
                        <td className="py-6">{item.file_name}</td>
                        <td>{item.distinct_profiles}</td>
                        <td className="-m-4">
                          <button
                            className={`first-letter:capitalize ${colorCode(
                              "active"
                            )} font-medium text-[12px] py-0.5 px-2 rounded-2xl`}
                          >
                            {"active"}
                          </button>
                        </td>
                        <td>{dayjs(item.upload_end_time).fromNow()}</td>
                        <td
                          onClick={() => {
                            handleSideMenu(index);
                          }}
                          className="text-base relative leading-6"
                        >
                          <div className="font-extrabold text-xl text-gray-700 py-2 whitespace-no-wrap">
                            {segmentId === index && showSegmentSideMenu && (
                              <div
                                ref={segmentMenu}
                                className="absolute z-10 top-16 right-0"
                              >
                                <div className="cursor-default shadow-md bg-white w-56 rounded-lg">
                                  <div className="group">
                                    <button
                                      onClick={() => createAudience(item)}
                                      className={`flex px-4 gap-3 py-2.5 items-center rounded w-full  ${
                                        tenantContext.productList[
                                          "audience_create_policy"
                                        ] && "hover:bg-gray-100"
                                      } relative`}
                                      disabled={
                                        !tenantContext.productList[
                                          "audience_create_policy"
                                        ]
                                      }
                                    >
                                      <div className="flex justify-center items-center">
                                        <img src={EngageSegments} alt="" />
                                      </div>
                                      <div className="text-gray-700 flex justify-center items-center text-base font-medium leading-6">
                                        {" "}
                                        Create Audience
                                        {!tenantContext.productList[
                                          "audience_create_policy"
                                        ] && (
                                          <div className="absolute hidden group-hover:flex bg-black p-3 text-xs text-white rounded-lg w-[300px] right-[200px] top-[-12px] z-20">
                                            {MESSAGE}
                                          </div>
                                        )}
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            <img src={Action} alt="" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            {dataList.length !== 0 && (
              <div className="mt-8">
                <PaginationComp
                  onPageChange={(from, size) => {
                    fetchFileUploads(from, size);
                  }}
                  reset
                  totalCount={totalListLength}
                />
              </div>
            )}
          </div>
          {showConnectModal && (
            <ConnectDataSourceModal
              navigate={navigate}
              closeModal={(status) => onDatasourceModalClose(status)}
              setHighlightId={setHighlightId}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CsvUploads;
