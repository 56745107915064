const Database = () => {

  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.65625" width="30" height="30" rx="15" fill="#97A8FF" />
      <g clip-path="url(#clip0_2806_93026)">
        <path
          d="M13.1747 20.8501C13.1992 20.8527 13.224 20.8486 13.2464 20.8383C13.2688 20.828 13.288 20.8118 13.302 20.7914C13.316 20.7712 13.3244 20.7475 13.3263 20.7229C13.3281 20.6983 13.3234 20.6736 13.3125 20.6514C13.0573 20.1326 12.9069 19.5686 12.8698 18.9916C12.8677 18.9597 12.8545 18.9295 12.8327 18.9062C12.8108 18.8829 12.7815 18.8679 12.7498 18.8638C11.5294 18.7571 10.3576 18.3356 9.34878 17.6405C9.3285 17.624 9.30393 17.6135 9.27794 17.6104C9.25196 17.6072 9.22561 17.6115 9.20197 17.6228C9.17833 17.634 9.15836 17.6517 9.14439 17.6739C9.13042 17.696 9.12302 17.7217 9.12305 17.7478V18.6973C9.12305 19.7485 10.8543 20.6216 13.1747 20.8501Z"
          fill="white"
        />
        <path
          d="M9.12305 16.209C9.12305 17.1894 10.6363 18.0193 12.7315 18.3114C12.7498 18.314 12.7684 18.3129 12.7862 18.3081C12.8041 18.3034 12.8208 18.2951 12.8353 18.2838C12.8499 18.2726 12.8621 18.2585 12.8712 18.2424C12.8802 18.2263 12.886 18.2086 12.8881 18.1903C12.9509 17.6468 13.1149 17.1198 13.3717 16.6367C13.3824 16.6165 13.3879 16.5939 13.3877 16.5711C13.3876 16.5482 13.3817 16.5257 13.3708 16.5057C13.3598 16.4856 13.3441 16.4686 13.3249 16.4561C13.3057 16.4436 13.2838 16.4361 13.261 16.4342C11.7002 16.292 10.2147 15.8571 9.34878 15.1522C9.3285 15.1357 9.30393 15.1252 9.27794 15.1221C9.25196 15.119 9.22561 15.1233 9.20197 15.1345C9.17833 15.1457 9.15836 15.1635 9.14439 15.1856C9.13042 15.2077 9.12302 15.2334 9.12305 15.2596V16.209Z"
          fill="white"
        />
        <path
          d="M14.3797 13.994C12.4433 13.994 10.4205 13.5331 9.34878 12.66C9.3285 12.6435 9.30393 12.633 9.27794 12.6299C9.25196 12.6268 9.22561 12.6311 9.20197 12.6423C9.17833 12.6535 9.15836 12.6713 9.14439 12.6934C9.13042 12.7155 9.12302 12.7412 9.12305 12.7674V13.7173C9.12305 14.8532 11.1547 15.7888 13.7706 15.9161C13.7916 15.9166 13.8124 15.9124 13.8316 15.9038C13.8507 15.8952 13.8677 15.8824 13.8812 15.8663C14.49 15.1305 15.3216 14.6127 16.2505 14.391C17.1793 14.1692 18.1551 14.2555 19.0306 14.6369C19.0556 14.6476 19.0832 14.6507 19.1099 14.6459C19.1367 14.6411 19.1614 14.6285 19.1811 14.6098C19.3141 14.5003 19.4232 14.3645 19.5013 14.211C19.5795 14.0574 19.6251 13.8894 19.6353 13.7173V12.7685C19.6354 12.7423 19.628 12.7167 19.614 12.6945C19.6 12.6724 19.5801 12.6547 19.5564 12.6434C19.5328 12.6322 19.5064 12.6279 19.4804 12.631C19.4545 12.6341 19.4299 12.6446 19.4096 12.6611C18.3379 13.5337 16.3184 13.994 14.3797 13.994Z"
          fill="white"
        />
        <path
          d="M14.3811 13.4419C17.284 13.4419 19.6373 12.451 19.6373 11.2287C19.6373 10.0065 17.284 9.01562 14.3811 9.01562C11.4783 9.01562 9.125 10.0065 9.125 11.2287C9.125 12.451 11.4783 13.4419 14.3811 13.4419Z"
          fill="white"
        />
        <path
          d="M17.2819 15.1016C16.5706 15.1016 15.8753 15.3125 15.2839 15.7077C14.6924 16.1028 14.2315 16.6645 13.9593 17.3216C13.6871 17.9788 13.6159 18.7019 13.7547 19.3995C13.8934 20.0971 14.2359 20.7379 14.7389 21.2408C15.2418 21.7438 15.8826 22.0863 16.5803 22.2251C17.2779 22.3638 18.001 22.2926 18.6581 22.0204C19.3152 21.7482 19.8769 21.2873 20.2721 20.6959C20.6673 20.1045 20.8782 19.4092 20.8782 18.6979C20.877 17.7444 20.4977 16.8304 19.8235 16.1562C19.1494 15.482 18.2353 15.1027 17.2819 15.1016ZM15.4837 18.6979C15.4837 18.5878 15.5274 18.4823 15.6052 18.4045C15.6831 18.3266 15.7886 18.2829 15.8987 18.2829H16.7286C16.7653 18.2829 16.8004 18.2683 16.8264 18.2424C16.8523 18.2165 16.8669 18.1813 16.8669 18.1446V17.3147C16.8669 17.2046 16.9106 17.0991 16.9884 17.0213C17.0663 16.9434 17.1718 16.8997 17.2819 16.8997C17.3919 16.8997 17.4975 16.9434 17.5753 17.0213C17.6531 17.0991 17.6968 17.2046 17.6968 17.3147V18.1446C17.6968 18.1813 17.7114 18.2165 17.7373 18.2424C17.7633 18.2683 17.7985 18.2829 17.8351 18.2829H18.6651C18.7751 18.2829 18.8807 18.3266 18.9585 18.4045C19.0363 18.4823 19.08 18.5878 19.08 18.6979C19.08 18.8079 19.0363 18.9135 18.9585 18.9913C18.8807 19.0691 18.7751 19.1128 18.6651 19.1128H17.8351C17.7985 19.1128 17.7633 19.1274 17.7373 19.1533C17.7114 19.1793 17.6968 19.2145 17.6968 19.2512V20.0811C17.6968 20.1911 17.6531 20.2967 17.5753 20.3745C17.4975 20.4523 17.3919 20.496 17.2819 20.496C17.1718 20.496 17.0663 20.4523 16.9884 20.3745C16.9106 20.2967 16.8669 20.1911 16.8669 20.0811V19.2512C16.8669 19.2145 16.8523 19.1793 16.8264 19.1533C16.8004 19.1274 16.7653 19.1128 16.7286 19.1128H15.8987C15.7886 19.1128 15.6831 19.0691 15.6052 18.9913C15.5274 18.9135 15.4837 18.8079 15.4837 18.6979Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2806_93026">
          <rect
            width="13.2787"
            height="13.2787"
            fill="white"
            transform="translate(8.36133 9.01562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Database;
