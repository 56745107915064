export const CONNECTION_TEST_STATUS = {
    NOT_STARTED: 'not_started',
    STARTED: 'started',
    SUCCESSFUL: 'successful',
    FAILED: 'failed'
}

export const replicationFrequency = [
    { label: 'Manual', value: 'manual', units: 0, timeUnit: "none" },
    { label: 'Every 5 min', value: 'basic', units: 5, timeUnit: "minutes" },
    { label: 'Every 10 min', value: 'basic', units: 10, timeUnit: "minutes" },
    { label: 'Every 15 min', value: 'basic', units: 15, timeUnit: "minutes" },
    { label: 'Every 1 hour ', value: 'basic', units: 1, timeUnit: "hours" },
    { label: 'Every 2 hours', value: 'basic', units: 2, timeUnit: "hours" },
    { label: 'Every 3 hours', value: 'basic', units: 3, timeUnit: "hours" },
    { label: 'Every 6 hours', value: 'basic', units: 6, timeUnit: "hours" },
    { label: 'Every 8 hours', value: 'basic', units: 8, timeUnit: "hours" },
    { label: 'Every 12 hours', value: 'basic', units: 12, timeUnit: "hours" },
    { label: 'Every 24 hours', value: 'basic', units: 24, timeUnit: "hours" }
]

export const sampleSchemas = [
    {
        "name": "users",
        "jsonSchema": {
            "type": "object",
            "properties": {
                "_ab_cdc_log_file": {
                    "type": "string"
                },
                "_ab_cdc_log_pos": {
                    "type": "number"
                },
                "_ab_cdc_updated_at": {
                    "type": "string"
                },
                "_ab_cdc_deleted_at": {
                    "type": "string"
                },
                "id": {
                    "airbyte_type": "integer",
                    "type": "number"
                },
                "_ab_cdc_cursor": {
                    "airbyte_type": "integer",
                    "type": "number"
                },
                "email": {
                    "type": "string"
                },
                "username": {
                    "type": "string"
                }
            }
        },
        "supportedSyncModes": [
            "full_refresh",
            "incremental"
        ],
        "sourceDefinedCursor": true,
        "defaultCursorField": [
            "_ab_cdc_cursor"
        ],
        "sourceDefinedPrimaryKey": [
            [
                "id"
            ]
        ],
        "namespace": "sample_db"
    },
    {
        "name": "USERSLIST",
        "jsonSchema": {
            "type": "object",
            "properties": {
                "_ab_cdc_log_file": {
                    "type": "string"
                },
                "_ab_cdc_log_pos": {
                    "type": "number"
                },
                "_ab_cdc_updated_at": {
                    "type": "string"
                },
                "_ab_cdc_deleted_at": {
                    "type": "string"
                },
                "id": {
                    "airbyte_type": "integer",
                    "type": "number"
                },
                "_ab_cdc_cursor": {
                    "airbyte_type": "integer",
                    "type": "number"
                },
                "email": {
                    "type": "string"
                },
                "username": {
                    "type": "string"
                }
            }
        },
        "supportedSyncModes": [
            "full_refresh",
            "incremental"
        ],
        "sourceDefinedCursor": true,
        "defaultCursorField": [
            "_ab_cdc_cursor"
        ],
        "sourceDefinedPrimaryKey": [
            [
                "id"
            ]
        ],
        "namespace": "sample_db"
    }
]