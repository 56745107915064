import { useReducer, useState } from "react";
import InfoIcon from "../assets/icons/InfoIcon";
import MySql from "../assets/icons/MySql";
import { Close } from "../assets/icons/icons";
import ArrowDown from "../assets/icons/ArrowDown";
import Spinner from "../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import {
  createSourceRequest,
  getSourceSchemaRequest,
  testConnectionRequest,
} from "../../api/dataServices";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { CONNECTION_TEST_STATUS } from "../constants/data.constants";
import MySqlConnectorFinal from "./MySqlConnectorFinal";

const MySQLConnector = ({ closeModal, sourceId, navigate }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: false,
          },
        };
      case "SUBMIT":
        const errors: any = {};
        if (!state.sourceName) {
          errors.sourceName = true;
        }
        if (!state.host) {
          errors.host = true;
        }
        if (!state.port) {
          errors.port = true;
        }
        if (!state.username) {
          errors.username = true;
        }
        if (!state.password) {
          errors.password = true;
        }
        if (!state.databaseName) {
          errors.databaseName = true;
        }
        // if (!state.sslModes) {
        //   errors.sslModes = true;
        // }
        return {
          ...state,
          errors: {
            ...errors,
          },
        };

      case "reinitialize":
        return { ...state, ...action.value };
      default:
        return state;
    }
  };

  const sourceFormInitValue = {
    sourceName: "",
    host: "",
    port: "",
    username: "",
    password: "",
    databaseName: "",
    sslModes: "Preferred",
    errors: {
      sourceName: false,
      host: false,
      port: false,
      username: false,
      password: false,
      databaseName: false,
      sslModes: false,
    },
  };

  const [pageNumer, setPageNumber] = useState(1);
  const [sourceForm, dispatch] = useReducer(reducer, sourceFormInitValue);
  const [connectionTestStatus, setConnectionTestStatus] = useState({
    trigger: false,
    ongoingTest: CONNECTION_TEST_STATUS.NOT_STARTED,
  });
  const [retestStatus, setRetestStatus] = useState({ status: "not_started" });
  const [mySqlSourceId, setMySqlSourceId] = useState(null);
  const [sourceSchema, setSourceSchema] = useState([]);

  const handleFormChange = (e) => {
    let { name, value } = e.target;
    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value: value,
      },
    });
  };

  const isValidaForm = (updatedState) => {
    const errors = updatedState.errors;
    let status = true;
    Object.keys(errors).map((key) => {
      if (errors[key] === true) {
        status = false;
        return;
      }
    });
    return status;
  };

  const createSource = () => {
    const updatedState = reducer(sourceForm, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });
    if (!isValidaForm(updatedState)) return;
    let data = {
      sourceId: sourceId,
      sourceDetails: {
        username: sourceForm.username,
        password: sourceForm.password,
        host: sourceForm.host,
        database: sourceForm.databaseName,
        port: Number(sourceForm.port),
        name: sourceForm.sourceName,
        replicationMethod: {
          method: "CDC",
          initial_waiting_seconds: 300,
          invalid_cdc_cursor_position_behavior: "Fail sync",
        },
        tunnelMethod: {
          tunnel_method: "NO_TUNNEL",
        },
        sslMode: {
          mode: "preferred",
        },
      },
    };
    createSourceRequest(data)
      .then((response) => {
        if (!response.error) {
          setRetestStatus({ status: "configure" });
          setMySqlSourceId(response?.response);
          getSourceSchemas(response?.response);
        } else {
          Notify({
            type: "failure",
            heading: "Failed",
            message: response.response,
          });
        }
      })
      .catch((error) => {
        setRetestStatus({ status: "not_started" });
        console.log(error);
      });
  };

  const getSourceSchemas = (id) => {
    getSourceSchemaRequest(id)
      .then((response) => {
        if (!response.error) {
          console.log(response);
          setSourceSchema(response.response);
          Notify({
            type: "success",
            heading: "Successful",
            message: "Source created successfully",
          });
          setPageNumber(2);
        } else {
          Notify({
            type: "failure",
            heading: "Failed",
            message: response.response,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const testConnection = () => {
    const updatedState = reducer(sourceForm, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });
    if (!isValidaForm(updatedState)) return;
    let connectionTestData = {
      sourceDetails: {
        replicationMethod: {
          method: "CDC",
          initial_waiting_seconds: 300,
          invalid_cdc_cursor_position_behavior: "Fail sync",
        },
        tunnelMethod: {
          tunnel_method: "NO_TUNNEL",
        },
        username: sourceForm.username,
        sslMode: {
          mode: "preferred",
        },
        password: sourceForm.password,
        database: sourceForm.databaseName,
        port: Number(sourceForm.port),
        host: sourceForm.host,
        ssl: true,
      },
      sourceDefinitionId: "435bb9a5-7887-4809-aa58-28c27df0d7ad",
    };
    setConnectionTestStatus({
      trigger: true,
      ongoingTest: CONNECTION_TEST_STATUS.STARTED,
    });
    setRetestStatus({ status: "retest" });
    testConnectionRequest(connectionTestData)
      .then((response) => {
        console.log(response);
        if (!response.error) {
          setConnectionTestStatus({
            trigger: true,
            ongoingTest: CONNECTION_TEST_STATUS.SUCCESSFUL,
          });
          setRetestStatus({ status: "not_started" });
          console.log(response);
        } else {
          setConnectionTestStatus({
            trigger: true,
            ongoingTest: CONNECTION_TEST_STATUS.FAILED,
          });
          setRetestStatus({ status: "not_started" });
          Notify({
            type: "failure",
            heading: "Test failed",
            message: response.response,
          });
        }
      })
      .catch((error) => {
        setConnectionTestStatus({
          trigger: true,
          ongoingTest: CONNECTION_TEST_STATUS.FAILED,
        });
        setRetestStatus({ status: "not_started" });
        console.log(error);
      });
  };

  return (
    <div className="absolute w-full h-full top-0 left-0 bg-dialog-overlay flex justify-center items-center">
      <div className="w-[720px] max-h-[600px] bg-white rounded-xl p-8 shadow-md overflow-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="text-gray-200 text-lg leading-6 font-semibold">
            Setup your source
          </div>
          <div className="cursor-pointer" onClick={() => closeModal()}>
            <img src={Close} alt="" />
          </div>
        </div>
        <div className="flex items-center gap-3 mt-6">
          <MySql />
          <div className="text-gray-500 text-sm font-semibold leading-6">
            MySQL Database
          </div>
        </div>
        <div className="mt-3 text-sm leading-6 text-gray-500">
          Connect your customer data to Marketing Cloud. Need help? Use our
          setup guide and follow the instructions or chat with an expert.
        </div>
        {pageNumer === 1 && (
          <>
            <div className="mt-6">
              <div className="text-gray-70 text-sm font-semibold leading-6">
                Source name
              </div>
              <div
                className={`border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px] ${
                  sourceForm.errors.sourceName && "border-red-500"
                }`}
              >
                <input
                  name="sourceName"
                  className="outline-none border-none h-full w-full text-sm"
                  placeholder="Source Name"
                  type="text"
                  value={sourceForm.sourceName}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="mt-6 w-full">
                <div className="text-gray-70 text-sm font-semibold leading-6">
                  Host
                </div>
                <div
                  className={`border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px]  ${
                    sourceForm.errors.host && "border-red-500"
                  }`}
                >
                  <input
                    name="host"
                    className="outline-none border-none h-full w-full text-sm"
                    placeholder="Enter the host name  of the database"
                    type="text"
                    value={sourceForm.host}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="mt-6 w-full">
                <div className="text-gray-70 text-sm font-semibold leading-6">
                  Port
                </div>
                <div
                  className={`border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px]  ${
                    sourceForm.errors.port && "border-red-500"
                  }`}
                >
                  <input
                    name="port"
                    className="outline-none border-none h-full w-full text-sm"
                    placeholder="The port to connect to e.g. 1521"
                    type="text"
                    value={sourceForm.port}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="mt-6 w-full">
                <div className="text-gray-70 text-sm font-semibold leading-6">
                  Username
                </div>
                <div
                  className={`border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px]  ${
                    sourceForm.errors.username && "border-red-500"
                  }`}
                >
                  <input
                    name="username"
                    className="outline-none border-none h-full w-full text-sm"
                    placeholder="The username used to access the database"
                    type="text"
                    value={sourceForm.username}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="mt-6 w-full">
                <div className="text-gray-70 text-sm font-semibold leading-6">
                  Password
                </div>
                <div
                  className={`border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px]  ${
                    sourceForm.errors.password && "border-red-500"
                  }`}
                >
                  <input
                    name="password"
                    className="outline-none border-none h-full w-full text-sm"
                    placeholder="Password"
                    type="text"
                    value={sourceForm.password}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="mt-6 w-full">
                <div className="text-gray-70 text-sm font-semibold leading-6">
                  Database name
                </div>
                <div
                  className={`border border-gray-40 w-full rounded-md p-1 mt-2 h-[40px]  ${
                    sourceForm.errors.databaseName && "border-red-500"
                  }`}
                >
                  <input
                    name="databaseName"
                    className="outline-none border-none h-full w-full text-sm"
                    placeholder="Enter database name"
                    type="text"
                    value={sourceForm.databaseName}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="mt-6 w-full">
                <div className="text-gray-70 text-sm font-semibold leading-6">
                  SSL modes
                </div>
                <div
                  className={`border border-gray-40 bg-[#fafafa] w-full rounded-md p-1 mt-2 h-[40px] flex items-center pr-3 cursor-not-allowed  ${
                    sourceForm.errors.sslModes && "border-red-500"
                  }`}
                >
                  <input
                    name="sslModes"
                    className="outline-none border-none h-full w-full text-sm"
                    placeholder="Source Name"
                    type="text"
                    value={sourceForm.sslModes}
                    disabled={true}
                  />
                  <div>
                    <ArrowDown />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-1">
                <div className="text-sm text-gray-70 font-semibold leading-6">
                  Update method
                </div>
                <div className="relative group">
                  <InfoIcon />
                  <div className="absolute hidden group-hover:flex bg-gray-900 rounded-lg p-3 text-xs leading-5 text-white w-max top-[-10px] left-[22px]">
                    Configures how data is extracted from the database.
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="flex gap-2 mt-4 border border-gray-70 rounded-md p-3">
                <div className="flex justify-center items-center shrink-0 h-[16px] w-[16px] rounded-full bg-gray-712 border border-gray-713">
                  <div className="h-[6px] w-[6px] bg-gray-70 rounded-full"></div>
                </div>
                <div>
                  <div className="text-gray-70 font-semibold text-sm leading-4">
                    Read Changes using Binary Log (CDC)
                  </div>
                  <div className="mt-1 text-gray-500 text-sm leading-5">
                    Recommended - Incrementally reads new inserts, updates, and
                    deletes using the MySQL binary log. This must be enabled on
                    your database.
                  </div>
                </div>
              </div>
              <div className="flex gap-2 mt-4 rounded-md p-3">
                <div className="flex shrink-0 h-[16px] w-[16px] rounded-full bg-gray-712 border border-gray-713">
                  <div className="h-[6px] w-[6px]"></div>
                </div>
                <div>
                  <div className="text-gray-500 font-semibold text-sm leading-4">
                    Scan Changes with User Defined Cursor
                  </div>
                  <div className="mt-1 text-gray-500 text-sm leading-5">
                    Incrementally detects new inserts and updates using the
                    cursor column chosen when configuring a connection (e.g.
                    created_at, updated_at).
                  </div>
                </div>
              </div>
            </div>
            {connectionTestStatus.trigger && (
              <div className="mt-6">
                <div className="bg-gray-60 border border-gray-711 px-8 py-6 text-gray-200 text-sm leading-4 flex justify-between items-center rounded-md">
                  {connectionTestStatus.ongoingTest ===
                    CONNECTION_TEST_STATUS.STARTED && (
                    <div>Testing source...</div>
                  )}
                  {connectionTestStatus.ongoingTest ===
                    CONNECTION_TEST_STATUS.SUCCESSFUL && (
                    <div>All tests successful!</div>
                  )}
                  {connectionTestStatus.ongoingTest ===
                    CONNECTION_TEST_STATUS.FAILED && <div>Test failed</div>}
                  {(connectionTestStatus.ongoingTest ===
                    CONNECTION_TEST_STATUS.STARTED ||
                    retestStatus.status === "retest") && (
                    <Spinner className="w-8 h-8" />
                  )}
                </div>
              </div>
            )}
            {connectionTestStatus.ongoingTest !==
              CONNECTION_TEST_STATUS.SUCCESSFUL && (
              <div className="mt-6">
                <button
                  onClick={() => testConnection()}
                  className="text-sm font-semibold leading-4 px-5 py-3 cursor-pointer rounded-md bg-gray-200 text-white"
                  disabled={
                    connectionTestStatus.ongoingTest ===
                    CONNECTION_TEST_STATUS.STARTED
                  }
                >
                  Save and test connection
                </button>
              </div>
            )}
            {connectionTestStatus.ongoingTest ===
              CONNECTION_TEST_STATUS.SUCCESSFUL && (
              <div className="flex items-center gap-3">
                <div className="mt-6">
                  <button
                    onClick={() => createSource()}
                    className="text-sm font-semibold leading-4 px-5 py-3 cursor-pointer rounded-md bg-gray-200 text-white"
                    disabled={retestStatus.status === "configure"}
                  >
                    Configure connection
                  </button>
                </div>
                <div className="mt-6">
                  <button
                    onClick={() => testConnection()}
                    className="text-sm font-semibold border border-gray-50 leading-4 px-5 py-3 cursor-pointer rounded-md bg-white text-gray-200"
                    disabled={retestStatus.status === "retest"}
                  >
                    Re-test source
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        {pageNumer === 2 && (
          <MySqlConnectorFinal
            sourceId={mySqlSourceId}
            setPageNumber={setPageNumber}
            name={sourceForm.sourceName}
            sourceSchema={sourceSchema}
            navigate={navigate}
          />
        )}
      </div>
    </div>
  );
};

export default MySQLConnector;
