const UpgradePlan = () => {
  return (
    <svg
      width="212"
      height="125"
      viewBox="0 0 212 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.24">
        <mask id="path-2-inside-1_80762_240774" fill="white">
          <path d="M50 56C50 49.3726 58.7304 44 69.5 44Lnan nanL69.5 44C80.2696 44 89 49.3726 89 56V135C89 141.627 80.2696 147 69.5 147Lnan nanL69.5 147C58.7304 147 50 141.627 50 135V56Z" />
        </mask>
        <path
          d="M88 56V135H90V56H88ZM51 135V56H49V135H51ZM69.5 146C64.2698 146 59.5826 144.693 56.2355 142.634C52.8722 140.564 51 137.837 51 135H49C49 138.79 51.493 142.064 55.1873 144.337C58.8978 146.62 63.9606 148 69.5 148V146ZM88 135C88 137.837 86.1278 140.564 82.7645 142.634C79.4174 144.693 74.7302 146 69.5 146V148C75.0394 148 80.1022 146.62 83.8127 144.337C87.507 142.064 90 138.79 90 135H88ZM69.5 45C74.7302 45 79.4174 46.3066 82.7645 48.3664C86.1278 50.4361 88 53.1631 88 56H90C90 52.2095 87.507 48.9365 83.8127 46.6631C80.1022 44.3797 75.0394 43 69.5 43V45ZM69.5 43C63.9606 43 58.8978 44.3797 55.1873 46.6631C51.493 48.9365 49 52.2095 49 56H51C51 53.1631 52.8722 50.4361 56.2355 48.3664C59.5826 46.3066 64.2698 45 69.5 45V43Z"
          fill="#9DD9BB"
          mask="url(#path-2-inside-1_80762_240774)"
        />
        <mask id="path-4-inside-2_80762_240774" fill="white">
          <path d="M85.5 33C85.5 26.3726 93.7827 21 104 21Lnan nanL104 21C114.217 21 122.5 26.3726 122.5 33V135C122.5 141.627 114.217 147 104 147Lnan nanL104 147C93.7827 147 85.5 141.627 85.5 135V33Z" />
        </mask>
        <path
          d="M121.5 33V135H123.5V33H121.5ZM86.5 135V33H84.5V135H86.5ZM104 146C99.0558 146 94.626 144.698 91.4627 142.646C88.2897 140.588 86.5 137.862 86.5 135H84.5C84.5 138.765 86.8517 142.039 90.3743 144.324C93.9067 146.616 98.7269 148 104 148V146ZM121.5 135C121.5 137.862 119.71 140.588 116.537 142.646C113.374 144.698 108.944 146 104 146V148C109.273 148 114.093 146.616 117.626 144.324C121.148 142.039 123.5 138.765 123.5 135H121.5ZM104 22C108.944 22 113.374 23.3018 116.537 25.3537C119.71 27.4119 121.5 30.1376 121.5 33H123.5C123.5 29.235 121.148 25.9607 117.626 23.6758C114.093 21.3845 109.273 20 104 20V22ZM104 20C98.7269 20 93.9067 21.3845 90.3743 23.6758C86.8517 25.9607 84.5 29.235 84.5 33H86.5C86.5 30.1376 88.2897 27.4119 91.4627 25.3537C94.626 23.3018 99.0558 22 104 22V20Z"
          fill="#FCB415"
          mask="url(#path-4-inside-2_80762_240774)"
        />
        <mask id="path-6-inside-3_80762_240774" fill="white">
          <path d="M119 81C119 74.3726 127.954 69 139 69Lnan nanL139 69C150.046 69 159 74.3726 159 81V135C159 141.627 150.046 147 139 147Lnan nanL139 147C127.954 147 119 141.627 119 135V81Z" />
        </mask>
        <path
          d="M158 81V135H160V81H158ZM120 135V81H118V135H120ZM139 146C133.627 146 128.812 144.691 125.372 142.628C121.913 140.552 120 137.824 120 135H118C118 138.803 120.564 142.075 124.343 144.343C128.143 146.622 133.327 148 139 148V146ZM158 135C158 137.824 156.087 140.552 152.628 142.628C149.188 144.691 144.373 146 139 146V148C144.673 148 149.857 146.622 153.657 144.343C157.436 142.075 160 138.803 160 135H158ZM139 70C144.373 70 149.188 71.3087 152.628 73.3722C156.087 75.4477 158 78.1756 158 81H160C160 77.197 157.436 73.9249 153.657 71.6572C149.857 69.3776 144.673 68 139 68V70ZM139 68C133.327 68 128.143 69.3776 124.343 71.6572C120.564 73.9249 118 77.197 118 81H120C120 78.1756 121.913 75.4477 125.372 73.3722C128.812 71.3087 133.627 70 139 70V68Z"
          fill="#53AC80"
          mask="url(#path-6-inside-3_80762_240774)"
        />
      </g>
      <path
        d="M55.7441 61.7191C55.7441 54.7485 61.3949 49.0977 68.3656 49.0977C75.3362 49.0977 80.987 54.7485 80.987 61.7191V144.81C80.987 151.781 75.3362 157.431 68.3656 157.431C61.3949 157.431 55.7441 151.781 55.7441 144.81V61.7191Z"
        fill="#9DD9BB"
      />
      <path
        d="M88.3496 49.098C88.3496 42.1274 94.0004 36.4766 100.971 36.4766C107.942 36.4766 113.592 42.1274 113.592 49.098V156.38C113.592 163.351 107.942 169.001 100.971 169.001C94.0004 169.001 88.3496 163.351 88.3496 156.38V49.098Z"
        fill="#FCB415"
      />
      <path
        d="M120.955 75.3909C120.955 68.4203 126.606 62.7695 133.576 62.7695C140.547 62.7695 146.198 68.4203 146.198 75.3909V132.187C146.198 139.158 140.547 144.809 133.576 144.809C126.606 144.809 120.955 139.158 120.955 132.187V75.3909Z"
        fill="#53AC80"
      />
      <path
        d="M177.665 80.5265C177.114 87.488 171.024 92.6846 164.062 92.1335C157.101 91.5824 151.904 85.4922 152.455 78.5307C153.006 71.5692 159.097 66.3726 166.058 66.9237C173.02 67.4748 178.216 73.565 177.665 80.5265Z"
        fill="#53AC80"
      />
      <path
        opacity="0.59"
        d="M168.614 75.8217C168.488 77.4129 167.096 78.6007 165.505 78.4747C163.914 78.3487 162.726 76.9567 162.852 75.3655C162.978 73.7743 164.37 72.5865 165.961 72.7125C167.552 72.8384 168.74 74.2305 168.614 75.8217Z"
        fill="white"
      />
      <mask
        id="mask0_80762_240774"
        maskUnits="userSpaceOnUse"
        x="157"
        y="78"
        width="15"
        height="9"
      >
        <path
          d="M170.368 86.1084C170.947 86.1542 171.462 85.7203 171.427 85.1404C171.335 83.5999 170.775 82.1244 169.816 80.9395C168.639 79.4872 166.959 78.5905 165.144 78.4468C163.329 78.3032 161.529 78.9242 160.139 80.1734C159.005 81.1925 158.219 82.5615 157.887 84.0685C157.761 84.6357 158.201 85.1451 158.78 85.1909L170.368 86.1084Z"
          fill="#9DD9BB"
        />
      </mask>
      <g mask="url(#mask0_80762_240774)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M146.937 90.2752L176.835 73.3187L177.082 73.5156L147.184 90.4721L146.937 90.2752Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M137.842 89.5565L167.74 72.6L167.986 72.7969L138.088 89.7534L137.842 89.5565Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M148.767 90.4198L178.666 73.4632L178.912 73.6602L149.014 90.6167L148.767 90.4198Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M139.672 89.701L169.57 72.7445L169.816 72.9414L139.918 89.8979L139.672 89.701Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M150.597 90.5643L180.496 73.6078L180.742 73.8047L150.844 90.7612L150.597 90.5643Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M141.504 89.8455L171.402 72.889L171.648 73.0859L141.75 90.0425L141.504 89.8455Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M152.429 90.7088L182.328 73.7523L182.574 73.9492L152.676 90.9057L152.429 90.7088Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M143.334 89.9901L173.232 73.0336L173.479 73.2305L143.58 90.187L143.334 89.9901Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M154.259 90.8534L184.158 73.8968L184.404 74.0938L154.506 91.0503L154.259 90.8534Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M145.164 90.1346L175.062 73.1781L175.309 73.375L145.41 90.3315L145.164 90.1346Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M156.09 90.9979L185.988 74.0414L186.234 74.2383L156.336 91.1948L156.09 90.9979Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.922 91.1463L187.82 74.1898L188.066 74.3867L158.168 91.3432L157.922 91.1463Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M159.752 91.2909L189.65 74.3343L189.896 74.5312L159.998 91.4878L159.752 91.2909Z"
          fill="white"
        />
      </g>
      <path
        d="M182.054 78.5446C182.1 77.9655 182.606 77.5333 183.185 77.5792L202.058 79.0744C202.637 79.1203 203.07 79.6269 203.024 80.206C202.978 80.7851 202.471 81.2173 201.892 81.1714L183.019 79.6762C182.44 79.6303 182.008 79.1237 182.054 78.5446Z"
        fill="#53AC80"
      />
      <path
        d="M182.042 83.0407C182.088 82.4616 182.594 82.0294 183.173 82.0753L194.707 82.989C195.286 83.0349 195.718 83.5415 195.672 84.1206C195.627 84.6997 195.12 85.1319 194.541 85.086L183.007 84.1723C182.428 84.1264 181.996 83.6198 182.042 83.0407Z"
        fill="#53AC80"
      />
      <path
        d="M136.646 26.8859C136.094 33.8474 130.004 39.044 123.043 38.4929C116.081 37.9418 110.885 31.8516 111.436 24.8901C111.987 17.9286 118.077 12.7319 125.039 13.2831C132 13.8342 137.197 19.9244 136.646 26.8859Z"
        fill="#FCB416"
      />
      <path
        opacity="0.59"
        d="M127.595 22.1811C127.469 23.7723 126.077 24.9601 124.485 24.8341C122.894 24.7081 121.706 23.3161 121.832 21.7249C121.958 20.1337 123.35 18.9459 124.942 19.0718C126.533 19.1978 127.721 20.5899 127.595 22.1811Z"
        fill="white"
      />
      <mask
        id="mask1_80762_240774"
        maskUnits="userSpaceOnUse"
        x="116"
        y="24"
        width="15"
        height="9"
      >
        <path
          d="M129.349 32.4677C129.928 32.5136 130.442 32.0797 130.408 31.4998C130.316 29.9593 129.756 28.4838 128.796 27.2989C127.62 25.8465 125.94 24.9499 124.125 24.8062C122.31 24.6625 120.509 25.2836 119.119 26.5328C117.985 27.5519 117.2 28.9209 116.867 30.4278C116.742 30.995 117.181 31.5045 117.76 31.5503L129.349 32.4677Z"
          fill="#9DD9BB"
        />
      </mask>
      <g mask="url(#mask1_80762_240774)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M105.918 36.6346L135.816 19.6781L136.062 19.875L106.164 36.8315L105.918 36.6346Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M96.822 35.9159L126.72 18.9593L126.967 19.1562L97.0685 36.1128L96.822 35.9159Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M107.748 36.7791L137.646 19.8226L137.893 20.0195L107.994 36.976L107.748 36.7791Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M98.6521 36.0604L128.55 19.1039L128.797 19.3008L98.8986 36.2573L98.6521 36.0604Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.578 36.9237L139.476 19.9672L139.723 20.1641L109.824 37.1206L109.578 36.9237Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M100.484 36.2049L130.382 19.2484L130.629 19.4453L100.731 36.4018L100.484 36.2049Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M111.41 37.0682L141.308 20.1117L141.555 20.3086L111.656 37.2651L111.41 37.0682Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.314 36.3495L132.212 19.3929L132.459 19.5898L102.561 36.5464L102.314 36.3495Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M113.24 37.2127L143.138 20.2562L143.385 20.4531L113.487 37.4096L113.24 37.2127Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M104.146 36.494L134.044 19.5375L134.291 19.7344L104.393 36.6909L104.146 36.494Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M115.07 37.3573L144.968 20.4007L145.215 20.5977L115.317 37.5542L115.07 37.3573Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M116.902 37.5018L146.8 20.5453L147.047 20.7422L117.149 37.6987L116.902 37.5018Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M118.732 37.6502L148.63 20.6937L148.877 20.8906L118.979 37.8471L118.732 37.6502Z"
          fill="white"
        />
      </g>
      <path
        d="M141.034 23.8532C141.08 23.2741 141.587 22.8419 142.166 22.8878L161.039 24.383C161.618 24.4289 162.05 24.9355 162.004 25.5146C161.958 26.0937 161.452 26.5259 160.873 26.48L142 24.9848C141.42 24.9389 140.988 24.4323 141.034 23.8532Z"
        fill="#FCB416"
      />
      <path
        d="M141.022 28.3493C141.068 27.7702 141.575 27.338 142.154 27.3839L153.687 28.2976C154.267 28.3435 154.699 28.8501 154.653 29.4292C154.607 30.0083 154.1 30.4405 153.521 30.3946L141.988 29.4809C141.409 29.435 140.977 28.9284 141.022 28.3493Z"
        fill="#FCB416"
      />
      <path
        d="M49.3467 83.6827C48.7956 90.6442 42.7054 95.8409 35.7439 95.2898C28.7824 94.7386 23.5857 88.6484 24.1369 81.6869C24.688 74.7254 30.7782 69.5288 37.7397 70.0799C44.7012 70.6311 49.8978 76.7212 49.3467 83.6827Z"
        fill="#9DD9BB"
      />
      <path
        opacity="0.59"
        d="M40.2959 78.9779C40.1699 80.5691 38.7779 81.7569 37.1867 81.631C35.5955 81.505 34.4077 80.1129 34.5336 78.5217C34.6596 76.9305 36.0516 75.7427 37.6428 75.8687C39.234 75.9947 40.4218 77.3867 40.2959 78.9779Z"
        fill="white"
      />
      <mask
        id="mask2_80762_240774"
        maskUnits="userSpaceOnUse"
        x="29"
        y="81"
        width="15"
        height="9"
      >
        <path
          d="M42.0498 89.2646C42.6289 89.3104 43.1432 88.8765 43.1087 88.2967C43.017 86.7561 42.457 85.2806 41.4974 84.0958C40.3211 82.6434 38.6407 81.7468 36.8259 81.6031C35.0111 81.4594 33.2106 82.0805 31.8204 83.3297C30.6863 84.3488 29.9011 85.7178 29.5682 87.2247C29.4429 87.7919 29.8825 88.3013 30.4615 88.3472L42.0498 89.2646Z"
          fill="#9DD9BB"
        />
      </mask>
      <g mask="url(#mask2_80762_240774)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.6189 93.4315L48.5171 76.475L48.7637 76.6719L18.8654 93.6284L18.6189 93.4315Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.52316 92.7127L39.4214 75.7562L39.668 75.9531L9.76972 92.9096L9.52316 92.7127Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.4489 93.576L50.3472 76.6195L50.5938 76.8164L20.6955 93.7729L20.4489 93.576Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.3532 92.8573L41.2515 75.9007L41.498 76.0977L11.5998 93.0542L11.3532 92.8573Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.279 93.7205L52.1773 76.764L52.4238 76.9609L22.5256 93.9175L22.279 93.7205Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.1853 93.0018L43.0835 76.0453L43.3301 76.2422L13.4318 93.1987L13.1853 93.0018Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.1111 93.8651L54.0093 76.9086L54.2559 77.1055L24.3576 94.062L24.1111 93.8651Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.0154 93.1463L44.9136 76.1898L45.1602 76.3867L15.2619 93.3432L15.0154 93.1463Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.9411 94.0096L55.8394 77.0531L56.0859 77.25L26.1877 94.2065L25.9411 94.0096Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.8454 93.2909L46.7437 76.3343L46.9902 76.5312L17.092 93.4878L16.8454 93.2909Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.7712 94.1541L57.6695 77.1976L57.916 77.3945L28.0178 94.351L27.7712 94.1541Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.6032 94.2987L59.5015 77.3422L59.748 77.5391L29.8498 94.4956L29.6032 94.2987Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.4333 94.4471L61.3316 77.4906L61.5781 77.6875L31.6799 94.644L31.4333 94.4471Z"
          fill="white"
        />
      </g>
      <path
        d="M0.0829445 77.4938C0.128823 76.9147 0.635445 76.4825 1.21451 76.5284L20.0875 78.0237C20.6666 78.0695 21.0988 78.5762 21.0529 79.1552C21.0071 79.7343 20.5004 80.1665 19.9214 80.1207L1.04837 78.6254C0.469303 78.5795 0.0370656 78.0729 0.0829445 77.4938Z"
        fill="#9DD9BB"
      />
      <path
        d="M7.44623 81.9899C7.4921 81.4108 7.99873 80.9786 8.5778 81.0245L20.1113 81.9383C20.6904 81.9841 21.1226 82.4908 21.0767 83.0698C21.0308 83.6489 20.5242 84.0811 19.9451 84.0353L8.41165 83.1215C7.83258 83.0756 7.40035 82.569 7.44623 81.9899Z"
        fill="#9DD9BB"
      />
    </svg>
  );
};

export default UpgradePlan;
