import { useEffect, useState } from "react";
import ConnectDataSourceModal from "../../common/components/ConnectDataSourceModal";
import PlanUpgradeModal from "../../common/components/PlanUpgradeModal";
import ReachCustomerSuccessModal from "../../common/components/ReachCustomerSuccessModal";
import Connectors from "../../common/assets/icons/Connectors";
import { getAvaialableIntegrationSources } from "../../api/dataServices";
import Spinner from "../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import Csv from "../../common/assets/icons/Csv";
import Oracle from "../../common/assets/icons/Oracle";
import Sftp from "../../common/assets/icons/Sftp";
import Database from "../../common/assets/icons/Database";
import SettingsBlue from "../../common/assets/icons/SettingBlue";
import MySql from "../../common/assets/icons/MySql";
import MySQLConnector from "../../common/components/MySqlConnector";
import MicrosoftSqlServer from "../../common/assets/icons/MicrosoftSqlServer";
import Dropbox from "../../common/assets/icons/Dropbox";
import GoogleDrive from "../../common/assets/icons/GoogleDrive";
import { getAccountStat } from "../../../../marketing-cloud-main/src/common/apis/billing";

const DataConnectors = ({
  dispatch,
  setShowCommonLoader,
  navigate,
  tenantContext,
}) => {
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [planUpgradeModalStatus, setPlanUpgradeModalStatus] = useState(false);
  const [reachCustomerSupportModalStatus, setReachCustomerSupportModalStatus] =
    useState(false);
  const [dataSourceList, setDataSourceList] = useState(null);
  const [mySQLConnectorStatus, setMySQLConnectorStatus] = useState(false);
  const [planId, setPLanId] = useState(1);
  const [sourceId, setSourceId] = useState(null);
  const [, setHighlightId] = useState("");

  const onDatasourceModalClose = (reloadData) => {
    setShowConnectModal(false);
    if (reloadData) true; //fetchFileUploads();
  };

  const onMySqlModalClose = () => {
    setMySQLConnectorStatus(false);
  };

  useEffect(() => {
    Promise.all([getAccountStat(), getAvaialableIntegrationSources()])
      .then((res) => {
        const response = res[1];
        const status = res[0];
        if (!response.error) {
          setDataSourceList(response.response);
        }
        try {
          const planId = JSON.parse(status?.subscriptions[0])[0].tier_id;
          setPLanId(planId);
        } catch (error) {
          setPLanId(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const switchModal = (value) => {
    switch (value) {
      case "data":
        setReachCustomerSupportModalStatus(false);
        setPlanUpgradeModalStatus(false);
        setShowConnectModal(true);
        break;
      case "plan":
        setShowConnectModal(false);
        setPlanUpgradeModalStatus(true);
        break;
      case "marketing":
        setShowConnectModal(false);
        setReachCustomerSupportModalStatus(true);
        break;
    }
  };

  const getSourceIcon = (ingestionType) => {
    switch (ingestionType) {
      case "CSV Upload":
        return <Csv />;
      case "MySQL":
        return <MySql />;
      case "Microsoft SQL Server":
        return <MicrosoftSqlServer />;
      case "Oracle":
        return <Oracle />;
      case "Dropbox":
        return <Dropbox />;
      case "Google Drive":
        return <GoogleDrive />;
      case "sftp":
        return <Sftp />;
      case "request datasource":
        return <Database />;
      default:
        return <Csv />;
    }
  };

  const openModalForConnector = (connection, sourceId) => {
    setSourceId(sourceId);
    switch (connection) {
      case "CSV Upload":
        setShowConnectModal(true);
        break;
      case "MySQL":
        setMySQLConnectorStatus(true);
        break;
    }
  };

  return (
    <div className="py-8 px-10">
      <div className="p-6 border border-gray-100 rounded-md h-[153px] flex items-center">
        <div className="w-1/2">
          <div className="text-gray-200 text-2xl leading-8 font-semibold">
            Data connector repository
          </div>
          <div className="text-gray-300 text-sm leading-5 mt-2">
            Easily Integrate your customer data from various platforms and apps.
            Enrich and engage connected data to retain and acquire customers{" "}
          </div>
        </div>
        <div className="w-1/2 flex justify-end items-center">
          <Connectors />
        </div>
      </div>
      <div className="text-sm text-gray-200 font-semibold border-b-2 border-gray-200 flex gap-2 items-center mt-10 w-max pb-1">
        <div>Sources</div>
        <div className="h-[24px] bg-slate-700 rounded-full font-normal text-xs flex justify-center items-center px-2">
          {dataSourceList?.count}
        </div>
      </div>
      <div>
        {dataSourceList?.sources ? (
          Object.keys(dataSourceList.sources).map((item, i) => {
            return (
              <div className="mt-10" key={`item${i}`}>
                <div className="font-semibold text-base leading-6 text-gray-200">
                  {item}
                </div>
                <div className="flex gap-10">
                  {dataSourceList.sources[item].map((source, j) => {
                    return (
                      <div
                        key={`source${j}`}
                        className="w-[33%] p-6 border rounded-lg mt-6"
                      >
                        <div className="mt-3 mb-5 w-[30px] h-[30px]">
                          {getSourceIcon(source?.name)}
                        </div>
                        <div className="flex justify-start items-center gap-3 mb-2 relative">
                          <div
                            className={`text-gray-700 text-base font-semibold capitalize`}
                          >
                            {source?.name}
                          </div>
                          {!source?.isEnabled && planId === 0 && (
                            <div className="bg-blue-100 text-blue-150 whitespace-nowrap font-medium text-xs py-0.5 px-2 rounded-2xl leading-4">
                              Upgrade plan
                            </div>
                          )}
                          {!source?.isEnabled && (
                            <div className="bg-blue-100 p-1 rounded-full group">
                              <SettingsBlue />
                              <div className="absolute hidden group-hover:flex bg-black text-white p-3 text-xs rounded-lg w-auto right-[-15px] left-[-15px] top-[-64px] z-20">
                                This data integration would require you to
                                connect with a technical expert.
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className={`${
                            source?.isEnabled
                              ? "text-gray-500"
                              : "text-gray-300"
                          } text-[13px] mb-6`}
                        >
                          {source?.description}
                        </div>
                        <button
                          className={`py-2 px-5 text-sm font-semibold border rounded-lg bg-white border-gray-50 ${
                            source?.isEnabled && "hover:border-gray-500"
                          } ${
                            source?.isEnabled ? "text-gray-90" : "text-gray-300"
                          }`}
                          onClick={() => {
                            source?.isEnabled
                              ? openModalForConnector(
                                  source?.name,
                                  source?.sourceId
                                )
                              : planId === 0
                              ? setPlanUpgradeModalStatus(true)
                              : setReachCustomerSupportModalStatus(true);
                          }}
                          // onClick={() => openModalForConnector(source?.name)}
                        >
                          Connect
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-center h-[300px]">
            <Spinner className="w-8 h-8" />
          </div>
        )}
      </div>
      <div>
        {showConnectModal && (
          <ConnectDataSourceModal
            navigate={navigate}
            closeModal={(status) => onDatasourceModalClose(status)}
            setHighlightId={setHighlightId}
          />
        )}
        {mySQLConnectorStatus && (
          <MySQLConnector
            closeModal={() => {
              onMySqlModalClose();
            }}
            sourceId={sourceId}
            navigate={navigate}
          />
        )}
        {planUpgradeModalStatus && (
          <PlanUpgradeModal
            navigate={navigate}
            close={() => setPlanUpgradeModalStatus(false)}
          />
        )}
        {reachCustomerSupportModalStatus && (
          <ReachCustomerSuccessModal
            close={() => setReachCustomerSupportModalStatus(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DataConnectors;
