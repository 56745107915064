const Oracle = () => {

  return (
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82805 17.6561C3.92356 17.6561 0 13.7325 0 8.82805C0 3.92357 3.92356 0 8.82805 0H19.1719C24.0764 0 28 3.92357 28 8.82805C28 13.7325 24.0764 17.6561 19.1719 17.6561H8.82805ZM18.9045 14.5351C22.1147 14.5351 24.6114 11.9491 24.6114 8.82805C24.6114 5.61785 22.0255 3.12109 18.9045 3.12109H9.09552C5.88535 3.12109 3.38854 5.70703 3.38854 8.82805C3.38854 11.9491 5.97452 14.5351 9.09552 14.5351H18.9045Z"
        fill="#F80000"
      />
    </svg>
  );
};

export default Oracle;
