import {
  api,
  audienceApi,
  integrationApi,
} from '../../../marketing-cloud-main/src/common/services/common-api-service';
import * as Sentry from '@sentry/react';

export const getDataList = (params) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const { from = 0, size = 10, source = '', status = '' } = params;
  let url = `/v1/segment?from=${from}&size=${size}`;
  //let url = `/v1/data-spool/file-upload-logs?from=${from}&size=${size}`; old GET Call

  if (source) {
    url = url + `&source=${source}`;
  }
  if (status) {
    url = url + `&status=${status}`;
  }

  let data = {
    from: from,
    size: size,
  };

  return audienceApi
    .post(url, data, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getConnectors = (params) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const {
    from = 0,
    size = 10,
    searchQuery = '',
    status = '',
    type = '',
  } = params;
  let url = `/get-connections?from=${from}&size=${size}`;

  if (searchQuery) {
    url += `&searchQuery=${searchQuery}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  if (type) {
    url += `&type=${type}`;
  }

  return integrationApi
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getStreams = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  let url = `/get-streams/${id}`;

  return integrationApi
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const uploadFile = (body) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/upload/file`;
  let data = new FormData();
  data.append('file', body);
  data.append('source_type', 'customer');
  return audienceApi
    .post(url, data, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
      return error.response;
    });
};

export const getIngestionSources = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/data-ingestion`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getAvaialableIntegrationSources = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = '/get-available-sources';
  return integrationApi
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
      return error;
    });
};

export const testConnectionRequest = (data) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = '/test-source';
  return integrationApi
    .post(url, data, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
      return error.response.data;
    });
};

export const createSourceRequest = (data) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = '/create-sources';
  return integrationApi
    .post(url, data, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
      return error.response.data;
    });
};

export const getSourceSchemaRequest = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/get-source-schema/${id}`;
  return integrationApi
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
      return error.response.data;
    });
};

export const createConnectionRequest = (data) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = '/create-connection';
  return integrationApi
    .post(url, data, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
      return error.response.data;
    });
};
