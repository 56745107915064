import { useEffect, useReducer, useRef, useState } from "react";
import { ChevronLeft, ChevronDown, ChevronUp, X } from "react-feather";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import Oracle from "../assets/icons/Oracle";
import Sftp from "../assets/icons/Sftp";
import Csv from "../assets/icons/Csv";
import Database from "../assets/icons/Database";
import File from "../assets/icons/File";
import SelectFile from "../assets/icons/SelectFile";
import Chevron from "../assets/icons/Chevron";
import Loading from "../../../../marketing-cloud-main/src/common/assets/icons/Loading";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { getIngestionSources } from "../../api/dataServices";
import {
  getEnrichmentFields,
  uploadSegmentCSV,
} from "../../../../marketing-cloud-main/src/common/apis/audience";
import UpgradePlanModal from "../../../../marketing-cloud-main/src/modals/upgrade-plan-modal";
import PlanUpgradeModal from "./PlanUpgradeModal";
import AttributeOverwriteModal from "./AttributeOverwriteModal";
import Spinner from "../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { cappingCheck } from "../../../../marketing-cloud-main/src/common/utils/capping-check";
import * as Sentry from "@sentry/react";
import { getAccountStat } from "../../../../marketing-cloud-main/src/common/apis/billing";
import { formatNumbers } from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";

const ConnectDataSourceModal = ({ navigate, closeModal, setHighlightId }) => {
  const [loading, setLoading] = useState(false);
  const [ingestionSources, setIngestionSources] = useState([]);
  const [showDataTypeMenu, setShowDataTypeMenu] = useState(false);
  const [showDataPreview, setShowDataPreview] = useState(false);
  const [showOverwriteModal, setShowOverwriteModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [enrichmentFields, setEnrichmentFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [unselectedFields, setUnselectedFields] = useState([]);
  const [selectedEnrichmentField, setSelectedEnrichmentField] = useState("");
  const [overwriteFields, setOverwriteFields] = useState(null);
  const [currentTab, setCurrentTab] = useState(3);
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvArray, setCsvArray] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [normalizedCsvHeaders, setNormalizedCsvHeaders] = useState([]);
  const [csvLength, setCsvLength] = useState(0);
  const inputRef = useRef(null);
  const dataTypeMenu = useRef(null);
  const [planUpdgrade, setPlanUpgrade] = useState(false);
  const [inputToggle, setInputToggle] = useState(true);
  const [fileSizeValidator, setFileSizeValidator] = useState(false);
  const [planId, setPLanId] = useState(1);

  const fetchDataSources = () => {
    try {
      //setLoading(true);
      Promise.all([
        getAccountStat(),
        getIngestionSources(),
        getEnrichmentFields(),
      ])
        .then((res) => {
          const status = res[0];
          const sources = res[1];
          const csvEnrichmentFields = res[2];

          try {
            const planId = JSON.parse(status?.subscriptions[0])[0].tier_id;
            setPLanId(planId);
          } catch (error) {
            setPLanId(1);
          }

          const { error, response = {} } = sources;
          if (error === false) {
            setIngestionSources(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            Notify({
              type: "error",
              heading: "Failed to retrieve data sources.",
              message: error,
            });
            setIngestionSources([]);
          }

          const { error: enrichmentError, data = [] } = csvEnrichmentFields;
          if (enrichmentError === false) {
            const formattedFields = Object.entries(data).map(
              ([key, value]: [string, { DISPLAY_NAME: string }], index) => ({
                label: value.DISPLAY_NAME,
                value: key,
                id: index + 1,
              })
            );

            setEnrichmentFields(formattedFields);
            setUnselectedFields(formattedFields);
          } else {
            enrichmentError.response
              ? Sentry.captureMessage("Error", enrichmentError.response)
              : Sentry.captureMessage("Error", enrichmentError);
            Notify({
              type: "error",
              heading: "Failed to retrieve data sources.",
              message: enrichmentError,
            });
            setEnrichmentFields([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataSources();
  }, []);

  const handleFieldClick = (field, overwrite = false) => {
    if (planId === 0) {
      setShowUpgradeModal(true);
      return;
    }

    const index = unselectedFields.findIndex(
      (item) => item.value === field.value
    );
    if (index !== -1) {
      const updatedUnselectedFields = [...unselectedFields];
      updatedUnselectedFields.splice(index, 1);

      const normalizedLabel = field?.label
        ?.replace(/[^a-zA-Z0-9]/g, "")
        .toLowerCase();

      if (overwrite) {
        setShowOverwriteModal(false);
        setUnselectedFields(updatedUnselectedFields);
        setSelectedFields([...selectedFields, field]);
      } else if (normalizedCsvHeaders.includes(normalizedLabel)) {
        const index = normalizedCsvHeaders.findIndex(
          (header) => header === normalizedLabel
        );
        setSelectedEnrichmentField(field);
        setOverwriteFields({
          csvHeader: csvHeaders[index],
          mcHeader: field?.label,
        });
        setShowOverwriteModal(true);
      } else {
        setUnselectedFields(updatedUnselectedFields);
        setSelectedFields([...selectedFields, field]);
      }
    } else {
      const index = selectedFields.findIndex(
        (item) => item.value === field.value
      );
      const updatedSelectedFields = [...selectedFields];
      updatedSelectedFields.splice(index, 1);
      setSelectedFields(updatedSelectedFields);
      setUnselectedFields([...unselectedFields, field]);
    }
  };

  const sortedUnselectedFields = unselectedFields
    .slice()
    .sort((a, b) => a.id - b.id);

  const handleSearch = (value: string) => {
    if (planId === 0) {
      setShowUpgradeModal(true);
      return;
    }

    const filteredFields = enrichmentFields.filter(
      (field) =>
        field.label.toLowerCase().includes(value.toLowerCase()) &&
        !selectedFields.some(
          (selectedField) => selectedField.value === field.value
        )
    );
    setUnselectedFields(filteredFields);
  };

  const getSourceIcon = (ingestionType) => {
    switch (ingestionType) {
      case "csv data upload":
        return <Csv />;
      case "oracle database":
        return <Oracle />;
      case "sftp":
        return <Sftp />;
      case "request datasource":
        return <Database />;
      default:
        return <Csv />;
    }
  };

  const getSourceName = (ingestionType) => {
    switch (ingestionType) {
      case "csv data upload":
        return "CSV Data Upload";
      case "sftp":
        return "SFTP";
      default:
        return ingestionType;
    }
  };

  const closeOpenMenus = (e) => {
    if (
      dataTypeMenu.current &&
      showDataTypeMenu &&
      !dataTypeMenu.current.contains(e.target)
    ) {
      setShowDataTypeMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const formInitValue = {
    datasourceName: "",
    dataType: "",
    dsFile: false,
    waConsent: false,
    errors: {
      datasourceName: false,
      dataType: false,
      dsFile: false,
      waConsent: false,
    },
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: "",
          },
        };
      case "SUBMIT":
        const errors: any = {};
        if (!state.datasourceName.trim()) {
          errors.datasourceName = true;
        }
        if (!state.dataType.trim()) {
          errors.dataType = true;
        }
        if (!state.waConsent) {
          errors.waConsent = true;
        }
        if (!state.dsFile) {
          errors.dsFile = true;
        }
        return {
          ...state,
          errors: {
            ...errors,
          },
        };
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(reducer, formInitValue);

  const handleFormChange = (e) => {
    const { name, value, checked } = e.target;

    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value: name === "waConsent" ? checked : value,
      },
    });
  };

  const validateForm = () => {
    const { datasourceName, dataType, waConsent } = formState;
    if (dataType === "Generic data") {
      if (datasourceName && selectedFile) {
        return true;
      }
    } else if (dataType === "Whatsapp data") {
      if (datasourceName && selectedFile && waConsent) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (selectedFile) {
      readCsv();
    }

    dispatch({
      type: "CHANGE",
      payload: {
        name: "dsFile",
        value: selectedFile ? true : false,
      },
    });
  }, [selectedFile]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
      readCsv();
    }
  };

  const handleFileChange = (e) => {
    setFileSizeValidator(false);
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      if (
        parseFloat((e.target.files[0].size / Math.pow(1000, 2)).toFixed(2)) > 50
      ) {
        setFileSizeValidator(true);
        return;
      }
      setSelectedFile(e.target.files[0]);
      readCsv();
    } else {
      setSelectedFile(null);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    let status = await cappingCheck(csvArray.length, 3);
    if (!status) {
      setPlanUpgrade(true);
      return;
    }
    const updatedState = reducer(formState, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });

    if (!validateForm()) {
      return;
    } else {
      setLoading(true);
      uploadSegmentCSV(selectedFile, selectedFields)
        .then((response) => {
          if (!response.data.error) {
            const { segment, email, phone_number } = response?.data?.data;

            // First, check the phone_number uploaded_profiles, then email if phone_number is 0
            const profilesData =
              phone_number.uploaded_profiles > 0 ? phone_number : email;

            const {
              total_profiles,
              duplicate,
              uploaded_profiles,
              invalid_profiles,
            } = profilesData;

            const breakdownMessage = `Uploaded: ${uploaded_profiles}, Duplicate: ${duplicate}, Invalid: ${invalid_profiles}, Total: ${total_profiles}`;

            Notify({
              type: "success",
              heading: "Import successful",
              message: `${response.data.message} (${breakdownMessage}). It will take a few minutes to the file to appear on the list.`,
            });
            navigate(`/data/csv?h=${segment}`);
            setHighlightId(segment);
            setTimeout(() => {
              closeModal(false);
            }, 1000);
          } else {
            Sentry.captureMessage("Error", response.data.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          setLoading(false);
          return err;
        });
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    // const k = 1024;
    // const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const dm = decimals < 0 ? 0 : decimals;

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const readCsv = () => {
    if (selectedFile) {
      const file = selectedFile;
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result;
        processCSV(text);
      };

      reader.readAsText(file);
    }
  };

  const processCSV = (str, delim = ",") => {
    const splitCsvLine = (line) => {
      const regex = new RegExp(
        `${delim}(?=(?:(?:[^"]*"){2})*[^"]*$)` // Split on delimiter but ignore commas inside quotes
      );
      return line.split(regex).map((value) => value.replace(/^"|"$/g, "")); // Remove leading and trailing quotes
    };

    // Process headers
    const headers = splitCsvLine(str.slice(0, str.indexOf("\n")));
    const sanitizedHeaders = headers.map((item) => item.replace(/\r$/, ""));
    const normalizedHeaders = headers.map((item) =>
      item.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
    );
    setCsvHeaders(sanitizedHeaders);
    setNormalizedCsvHeaders(normalizedHeaders);

    // Process rows
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Remove last element if it's empty
    if (rows[rows.length - 1] === "") {
      rows.pop();
    }

    // Validation: Ensure all rows have a valid number of columns
    for (let index = 0; index < rows.length; index++) {
      const row = splitCsvLine(rows[index]);
      if (row.length > headers.length) {
        Notify({
          type: "error",
          heading: "Validation Error",
          message: `Row ${index + 1} has a mismatch in number of columns.`,
        });
        // Exit the function on invalid row
        setSelectedFile(null);
        return;
      }
    }

    setCsvLength(rows.length);

    // Create an array of objects for preview (limit to first 10 records)
    const previewRows = rows.slice(0, 10);
    const previewArray = previewRows.map((row) => {
      const values = splitCsvLine(row);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i] || ""; // Handle empty fields
        return obj;
      }, {});
      return eachObject;
    });

    setCsvArray(previewArray);
  };

  return (
    <div className="absolute w-screen h-screen left-0 top-0 bg-gray-950/[.4]">
      <div
        className="flex w-full h-full justify-center items-center"
        onClick={() => closeModal(false)}
      >
        <div
          className="w-[720px] p-8 bg-white rounded-md"
          onClick={(e) => e.stopPropagation()}
        >
          {/* {currentTab === 0 && (
            <div>
              <div className="flex justify-between mb-6">
                <div className="text-lg font-semibold">
                  Connect to a datasource
                </div>
                <X
                  className="text-gray-600 cursor-pointer rounded-full hover:bg-gray-30 hover:p-0.5"
                  size="18"
                  onClick={() => closeModal(false)}
                />
              </div>
              {loading ? (
                <div className="flex h-full w-full justify-center items-center">
                  <div className="mt-40 h-64">
                    <Spinner className="w-8 h-8" />
                  </div>
                </div>
              ) : (
                <>
                  {ingestionSources.length > 0 ? (
                    <div>
                      <div className="flex flex-wrap gap-y-4 justify-between mb-4">
                        {ingestionSources.map((source: any) => (
                          <div
                            key={source?.dataIngestionType}
                            className="w-[49%] p-6 border rounded-lg"
                          >
                            <div className="mt-3 mb-5 w-[30px] h-[30px]">
                              {getSourceIcon(source?.dataIngestionType)}
                            </div>

                            <div className="flex justify-start items-center gap-3 mb-2 relative">
                              <div
                                className={`text-gray-700 text-base font-semibold capitalize`}
                              >
                                {getSourceName(source?.dataIngestionType)}
                              </div>
                              {!source?.isEnabled && planId === 0 && (
                                <div className="bg-blue-100 text-blue-150 font-medium text-xs py-0.5 px-2 rounded-2xl leading-4">
                                  Upgrade plan
                                </div>
                              )}
                              {!source?.isEnabled && (
                                <div className="bg-blue-100 p-1 rounded-full group">
                                  <SettingsBlue />
                                  <div className="absolute hidden group-hover:flex bg-black text-white p-3 text-xs rounded-lg w-[300px] right-[-10px] top-[-64px] z-20">
                                    This data integration would require you to
                                    connect with a technical expert.
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className={`${
                                source?.isEnabled
                                  ? "text-gray-500"
                                  : "text-gray-300"
                              } text-[13px] mb-6`}
                            >
                              {source?.description}
                            </div>
                            <button
                              className={`py-2 px-5 text-sm font-semibold border rounded-lg bg-white border-gray-50 ${
                                source?.isEnabled && "hover:border-gray-500"
                              } ${
                                source?.isEnabled
                                  ? "text-gray-90"
                                  : "text-gray-300"
                              }`}

                              onClick={() => {
                                source?.isEnabled
                                  ? setCurrentTab(3)
                                  : planId === 0
                                  ? switchModal("plan")
                                  : switchModal("marketing");
                              }}
                            >
                              Connect
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center">
                      <div className="mt-48 text-gray-700 font-medium text-lg">
                        No data sources available
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )} */}

          {currentTab === 3 && (
            <div>
              <div className="flex justify-end mb-6">
                <X
                  className="text-gray-600 -mt-1 -mr-2.5 cursor-pointer rounded-full hover:bg-gray-30 hover:p-0.5"
                  size={18}
                  onClick={() => closeModal(false)}
                />
              </div>

              <div
                className={`overflow-auto max-h-[550px] -mr-8 ${
                  selectedFile ? "pr-6" : "pr-8"
                }`}
              >
                <div className="flex items-center gap-4 mb-4 ml-2">
                  <Csv width="25" height="32" />
                  <div className="text-base font-semibold text-gray-500">
                    CSV Upload
                  </div>
                </div>

                <div className="text-gray-500 mb-8">
                  Import your customer data to Marketing Cloud using CSV or
                  spreadsheets. Need help? Use our setup guide and follow the
                  instructions or chat with an expert.
                </div>

                <div>
                  <div className="flex justify-between">
                    <div className="mb-6 w-[49%]">
                      <div className="font-semibold">Datasource name</div>
                      <input
                        type="text"
                        name="datasourceName"
                        placeholder="Enter a name for this datasource"
                        className={`w-full h-12 p-3 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                          formState.errors.datasourceName && "border-red-500"
                        }`}
                        onChange={handleFormChange}
                      />
                    </div>
                    <div className="relative mb-6 w-[49%]">
                      <div className="font-semibold">Data type</div>
                      <button
                        className={`flex w-full justify-between items-center p-3 text-center h-12 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                          formState.dataType === "" && "text-gray-400"
                        } ${formState.errors.dataType && "border-red-500"}`}
                        name="dataType"
                        type="button"
                        onClick={() => setShowDataTypeMenu(!showDataTypeMenu)}
                      >
                        {formState.dataType || "Generic data or Whatsapp data"}
                        <Chevron />
                      </button>

                      {/* DataType Dropdown menu */}
                      {showDataTypeMenu && (
                        <div
                          ref={dataTypeMenu}
                          className="absolute z-10 bg-white rounded-lg shadow w-full"
                        >
                          {["Generic data", "Whatsapp data"].map(
                            (channel, index) => (
                              <button
                                key={index}
                                className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                                role="menuitem"
                                onClick={() => {
                                  dispatch({
                                    type: "CHANGE",
                                    payload: {
                                      name: "dataType",
                                      value: channel,
                                    },
                                  });
                                  setShowDataTypeMenu(false);
                                }}
                              >
                                {channel}
                              </button>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-6">
                    <div className="font-semibold">Upload your data</div>
                    <div className="text-gray-600 mt-1">
                      You can download the sample file to see how to structure
                      your data.{" "}
                      <span>
                        <a
                          href="https://mcassets.blob.core.windows.net/cdn/AudienceAssets/Audience_Sample_Template.csv"
                          download="Sample_Template.csv"
                          className="text-blue-75 font-semibold cursor-pointer"
                        >
                          Download sample file
                        </a>
                      </span>
                    </div>
                  </div>

                  <div onDragEnter={handleDrag}>
                    {inputToggle && (
                      <input
                        ref={inputRef}
                        type="file"
                        name="dsFile"
                        className="hidden"
                        multiple={false}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleFileChange}
                        onClick={(event: any) => {
                          event.target.value = null;
                        }}
                      />
                    )}
                    <div
                      className={`py-6 bg-gray-25 border border-dashed border-gray-200 rounded-lg cursor-pointer ${
                        formState.errors.dsFile && "border-red-500"
                      }`}
                      onClick={onButtonClick}
                    >
                      {!selectedFile && (
                        <div className="flex flex-col text-center items-center justify-center">
                          <SelectFile />
                          <p className="mt-4 text-sm">
                            <span className="text-blue-75 font-semibold cursor-pointer">
                              Click to upload
                            </span>
                            <span className="text-gray-500">
                              {" "}
                              or drag and drop it here
                            </span>
                          </p>
                          <p
                            className={`${
                              fileSizeValidator
                                ? "text-red-500"
                                : "text-gray-500"
                            } text-xs`}
                          >
                            {fileSizeValidator
                              ? "File exceeds the 50 MB upload limit. Please upload a smaller CSV or XLS file."
                              : "Only CSV, XLS supported. Max file size: 50 MB"}
                          </p>
                        </div>
                      )}

                      {selectedFile && (
                        <div className="flex justify-between items-center">
                          <div className="flex gap-3 items-center px-4">
                            <File />
                            <div className="text-gray-70">
                              <div className="text-base font-semibold">
                                {selectedFile?.name}
                              </div>
                              <div>{formatBytes(selectedFile?.size)}</div>
                            </div>
                          </div>
                          <X
                            className="text-gray-600 mr-2 cursor-pointer"
                            size="16"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedFile(null);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {dragActive && (
                      <div
                        className="absolute w-full h-full rounded-2xl top-0 right-0 bottom-0 left-0"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      ></div>
                    )}
                  </div>

                  {selectedFile && (
                    <div onClick={() => setShowDataPreview(!showDataPreview)}>
                      {showDataPreview ? (
                        <div className="pt-4 flex gap-1 items-center text-gray-600 cursor-pointer">
                          Hide Preview
                          <ChevronUp size="22" />
                        </div>
                      ) : (
                        <div className="pt-4 flex gap-1 items-center text-gray-600 cursor-pointer">
                          Show Preview
                          <ChevronDown className="mt-0.5" size="22" />
                        </div>
                      )}
                    </div>
                  )}

                  {selectedFile && showDataPreview && csvArray.length > 0 ? (
                    <div className="mt-6 px-4 py-2 bg-gray-25 border border-gray-200 rounded-lg">
                      <div className="overflow-x-auto">
                        <table className="min-w-full">
                          <thead>
                            <tr>
                              {Object.keys(csvArray[0]).map((item, i) => (
                                <th
                                  key={i}
                                  className="py-2 px-3 border-b border-gray-100 text-left text-gray-500"
                                >
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {csvArray.map((item, i) => (
                              <tr
                                key={i}
                                className={`${
                                  i === csvArray.length - 1 ? "" : "border-b"
                                } border-gray-100 text-gray-500`}
                              >
                                {Object.values(item).map((item, i) => (
                                  <td key={i} className="py-4 px-3">
                                    {item}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {csvLength > 10 && (
                        <div className="flex justify-center mt-2 text-sm italic text-gray-400">
                          Showing only 10 of {formatNumbers(csvLength)} records
                        </div>
                      )}
                    </div>
                  ) : null}

                  {selectedFile && (
                    <div className="mt-6 w-4/5">
                      <div className="flex justify-start items-center gap-3">
                        <div className="font-semibold">Enrichment</div>
                        {planId === 0 && (
                          <div className="bg-gray-150 text-blue-250 font-semibold text-xs pt-1 pb-1.5 px-2 rounded-2xl leading-4">
                            Upgrade plan to use
                          </div>
                        )}
                      </div>
                      <div className="text-gray-600 mt-1.5">
                        Select the attributes you want to enrich your data with.
                      </div>

                      <div
                        id="selected"
                        className={`flex gap-3 flex-wrap ${
                          selectedFields.length > 0 ? "py-4" : "pt-4"
                        }`}
                      >
                        {selectedFields.map((field) => (
                          <button
                            key={field.value}
                            className="px-3 py-1.5 text-sm flex gap-1.5 items-center bg-gray-100 text-gray-700 hover:text-red-500 font-semibold hover:bg-gray-40 rounded-full"
                            onClick={() => handleFieldClick(field)}
                          >
                            <div className="text-gray-700">{field.label}</div>
                            <IoClose className="text-2xl" size="12" />
                          </button>
                        ))}
                      </div>

                      <div
                        className={`flex border border-gray-100 rounded-lg ${
                          planId === 0 && "cursor-not-allowed"
                        }`}
                      >
                        <div
                          className="w-10 h-8 text-gray-400"
                          onClick={() => handleFieldClick(null)}
                        >
                          <FiSearch
                            className={`text-xl text-black m-3 mt-[6px] ${
                              planId === 0 && "cursor-not-allowed"
                            }`}
                            size="15"
                          />
                        </div>
                        <input
                          type="text"
                          placeholder="Search attributes"
                          className="w-full h-8 text-sm focus:border-none font-normal focus:outline-none"
                          onChange={(event) => handleSearch(event.target.value)}
                        />
                      </div>

                      <div
                        id="unselected"
                        className={`py-4 flex gap-3 flex-wrap ${
                          unselectedFields.length === 0 &&
                          "justify-center items-center"
                        }`}
                      >
                        {unselectedFields.length === 0 ? (
                          <div className="text-gray-400 mt-4">
                            No enrichment attribute available
                          </div>
                        ) : (
                          sortedUnselectedFields.map((field) => (
                            <button
                              key={field.value}
                              className={`px-3 py-1.5 text-sm border rounded-full ${
                                planId === 0
                                  ? "text-gray-200 border-gray-200"
                                  : "text-gray-400 border-gray-400 hover:bg-gray-100 hover:text-gray-600"
                              }`}
                              onClick={() => handleFieldClick(field)}
                            >
                              {field.label}
                            </button>
                          ))
                        )}
                      </div>
                    </div>
                  )}

                  {formState.dataType === "Whatsapp data" && (
                    <div className="mt-4">
                      <div className="flex items-center">
                        <input
                          id="whatsapp-data"
                          name="waConsent"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 border border-gray-300 rounded cursor-pointer accent-blue-75"
                          onChange={handleFormChange}
                        />
                        <label
                          htmlFor="whatsapp-data"
                          className={`ml-2 text-gray-700 cursor-pointer ${
                            formState.errors.waConsent &&
                            "border border-red-500 rounded-[4px] p-0.5"
                          }`}
                        >
                          Yes, I confirm{" "}
                          <span className="text-gray-400">
                            (for WhatsApp customer data)
                          </span>
                        </label>
                      </div>
                      <p className="ml-6 mt-1 text-[13px] text-gray-500">
                        I confirm that the uploaded users have opted in to
                        receive promotional messages from my WhatsApp Business
                        account. I understand any unauthorised usage can lead to
                        account closure.
                      </p>
                    </div>
                  )}

                  <div className="flex justify-end">
                    <button
                      className={`py-3 px-5 mt-6 font-semibold border rounded-md bg-blue-75 ${
                        loading
                          ? "text-blue-75"
                          : "text-white hover:bg-green-75"
                      }`}
                      type="submit"
                      onClick={handleUpload}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Loading className="text-blue-75" />
                          Uploading...
                        </>
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {planUpdgrade && (
        <UpgradePlanModal close={setPlanUpgrade} navigate={navigate} />
      )}

      {showUpgradeModal && (
        <PlanUpgradeModal
          navigate={navigate}
          close={() => setShowUpgradeModal(false)}
        />
      )}

      {showOverwriteModal && (
        <AttributeOverwriteModal
          mcHeader={overwriteFields?.mcHeader}
          csvHeader={overwriteFields?.csvHeader}
          overwrite={() => handleFieldClick(selectedEnrichmentField, true)}
          close={() => setShowOverwriteModal(false)}
        />
      )}
    </div>
  );
};

export default ConnectDataSourceModal;
