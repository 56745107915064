const Connectors = () => {
  return (
    <svg
      width="100"
      height="106"
      viewBox="0 0 100 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_81484_194041"
        maskUnits="userSpaceOnUse"
        x="7"
        y="23"
        width="83"
        height="83"
      >
        <circle cx="48.441" cy="64.3765" r="40.9976" fill="#EEF9F4" />
      </mask>
      <g mask="url(#mask0_81484_194041)">
        <path
          d="M73.4949 88.3125H23.3867V100.798H23.4724C24.5328 104.846 35.3066 108.027 48.4408 108.027C61.5751 108.027 72.3488 104.846 73.4092 100.798H73.4949V88.3125Z"
          fill="#53AC80"
        />
        <rect
          x="68.9395"
          y="95.8867"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <rect
          x="66.6621"
          y="97.0273"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <rect
          x="64.3848"
          y="98.1641"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <ellipse
          cx="29.5901"
          cy="99.8723"
          rx="1.69164"
          ry="1.70823"
          fill="#EEF9F4"
        />
        <ellipse
          cx="48.4408"
          cy="88.9677"
          rx="25.0541"
          ry="7.88566"
          fill="#9DD9BB"
        />
        <path
          d="M73.4949 73.5078H23.3867V85.9934H23.4724C24.5328 90.0413 35.3066 93.2223 48.4408 93.2223C61.5751 93.2223 72.3488 90.0413 73.4092 85.9934H73.4949V73.5078Z"
          fill="#53AC80"
        />
        <rect
          x="68.9395"
          y="81.082"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <rect
          x="66.6621"
          y="82.2227"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <rect
          x="64.3848"
          y="83.3594"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <ellipse
          cx="29.5901"
          cy="85.0676"
          rx="1.69164"
          ry="1.70823"
          fill="#EEF9F4"
        />
        <ellipse
          cx="48.4408"
          cy="74.163"
          rx="25.0541"
          ry="7.88566"
          fill="#9DD9BB"
        />
        <ellipse
          cx="48.4408"
          cy="59.3583"
          rx="25.0541"
          ry="7.88566"
          fill="#CAEEDC"
        />
        <path
          d="M48.4408 66.7644C62.2778 66.7644 73.4949 63.2681 73.4949 58.9553V70.669H73.4091C72.3481 74.6774 61.5746 77.8273 48.4408 77.8273C35.307 77.8273 24.5335 74.6774 23.4725 70.669H23.3867V58.9553C23.3867 63.2681 34.6038 66.7644 48.4408 66.7644Z"
          fill="#53AC80"
        />
        <path
          d="M23.4724 58.3047C23.4157 58.5192 23.3867 58.7362 23.3867 58.9553V58.3047H23.4724Z"
          fill="#53AC80"
        />
        <path
          d="M73.4092 58.3047H73.4949V58.9553C73.4949 58.7362 73.4659 58.5192 73.4092 58.3047Z"
          fill="#53AC80"
        />
        <rect
          x="68.9395"
          y="66.2773"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <rect
          x="66.6621"
          y="67.418"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <rect
          x="64.3848"
          y="68.5547"
          width="1.13882"
          height="5.69411"
          rx="0.569411"
          fill="#9DD9BB"
        />
        <ellipse
          cx="29.5901"
          cy="70.2629"
          rx="1.69164"
          ry="1.70823"
          fill="#EEF9F4"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4105 43.9744C18.6178 43.9429 18.8113 44.0854 18.8428 44.2926C18.9626 45.0808 19.168 45.8372 19.5111 46.4375C19.8499 47.0303 20.3135 47.457 20.9603 47.6328C21.5827 47.802 22.3127 47.7338 23.1546 47.582C23.3927 47.5391 23.6415 47.489 23.897 47.4375C24.5129 47.3135 25.1678 47.1816 25.8072 47.1233C26.7281 47.0393 27.6843 47.0976 28.5925 47.5506C29.3212 47.914 29.9896 48.5166 30.5793 49.4441L31.3873 47.0146C31.4535 46.8157 31.6684 46.7081 31.8673 46.7742C32.0662 46.8404 32.1739 47.0553 32.1077 47.2542L31.0295 50.4961C30.9633 50.695 30.7484 50.8027 30.5495 50.7365L27.3076 49.6583C27.1087 49.5921 27.0011 49.3772 27.0672 49.1783C27.1334 48.9794 27.3483 48.8717 27.5472 48.9379L29.8397 49.7003C29.3384 48.958 28.8009 48.5029 28.2537 48.23C27.5197 47.8639 26.722 47.8022 25.8761 47.8794C25.2792 47.9338 24.691 48.0524 24.0932 48.1729C23.8276 48.2264 23.5602 48.2803 23.2893 48.3292C22.4378 48.4827 21.5607 48.5828 20.7612 48.3654C19.8747 48.1245 19.2648 47.5367 18.8519 46.8142C18.4432 46.0991 18.2187 45.2391 18.0922 44.4067C18.0607 44.1994 18.2032 44.0058 18.4105 43.9744Z"
        fill="#53AC80"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7854 49.7539C21.2976 49.8368 21.7828 49.7952 22.06 49.7194C22.2622 49.6641 22.471 49.7832 22.5263 49.9854C22.5816 50.1876 22.4625 50.3964 22.2603 50.4517C21.8686 50.5588 21.2731 50.602 20.6641 50.5034C20.0543 50.4047 19.3837 50.1559 18.8917 49.633C18.748 49.4803 18.7553 49.2401 18.908 49.0964C19.0606 48.9527 19.3009 48.96 19.4446 49.1127C19.7829 49.4722 20.274 49.6712 20.7854 49.7539Z"
        fill="#53AC80"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.8768 45.7086C27.3605 45.6568 26.8787 45.7278 26.6066 45.8202C26.4081 45.8876 26.1925 45.7814 26.1251 45.5829C26.0576 45.3844 26.1639 45.1688 26.3624 45.1013C26.7468 44.9708 27.3387 44.8916 27.9525 44.9532C28.5672 45.0148 29.2516 45.2226 29.7744 45.7148C29.927 45.8585 29.9343 46.0987 29.7905 46.2514C29.6468 46.404 29.4066 46.4113 29.2539 46.2676C28.8945 45.9291 28.3923 45.7603 27.8768 45.7086Z"
        fill="#53AC80"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.7592 35.9157C77.5517 35.8722 77.3505 36.0018 77.3099 36.205C77.1201 37.1541 76.8906 38.1738 76.5061 39.0404C76.121 39.9085 75.6043 40.5682 74.8741 40.8716C73.3798 41.4926 71.8053 42.1777 70.4175 43.4471C69.1641 44.5936 68.0818 46.1977 67.3135 48.618L66.0238 46.4345C65.9169 46.2534 65.6823 46.1893 65.4998 46.2914C65.3174 46.3934 65.2562 46.6228 65.3632 46.8039L67.1059 49.7545C67.2128 49.9355 67.4474 49.9996 67.6299 49.8976L70.6027 48.2351C70.7852 48.1331 70.8463 47.9036 70.7394 47.7226C70.6325 47.5415 70.3979 47.4775 70.2154 47.5795L68.0683 48.7802C68.8014 46.5064 69.8114 45.0384 70.9444 44.0021C72.2259 42.8299 73.6882 42.1865 75.1826 41.5655C76.1668 41.1565 76.7889 40.2978 77.2089 39.351C77.6296 38.4028 77.8716 37.3113 78.0614 36.3623C78.102 36.1591 77.9667 35.9591 77.7592 35.9157Z"
        fill="#FCB416"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M76.9917 41.8199C76.5499 42.1954 76.0637 42.4499 75.7525 42.5518C75.5526 42.6173 75.4453 42.8307 75.5129 43.0284C75.5804 43.2262 75.7972 43.3334 75.9971 43.268C76.4113 43.1323 76.983 42.8257 77.4923 42.3928C78.0004 41.9609 78.4855 41.3696 78.66 40.6505C78.7091 40.4483 78.5826 40.244 78.3774 40.1942C78.1722 40.1445 77.966 40.2682 77.917 40.4705C77.7936 40.9787 77.4347 41.4433 76.9917 41.8199Z"
        fill="#FCB416"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.2629 41.9454C70.7213 41.6073 71.2047 41.3632 71.5095 41.2557C71.7073 41.1858 71.8107 40.9692 71.7406 40.7717C71.6704 40.5742 71.4532 40.4707 71.2554 40.5405C70.8726 40.6757 70.3233 40.9573 69.8108 41.3352C69.3033 41.7096 68.7876 42.2108 68.5288 42.8013C68.4447 42.9931 68.5325 43.2168 68.7247 43.3009C68.917 43.3851 69.141 43.2977 69.2251 43.1059C69.4064 42.6923 69.7996 42.2871 70.2629 41.9454Z"
        fill="#FCB416"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.4457 48.2473C50.235 48.2638 50.0805 48.445 50.1005 48.652C50.2794 50.4977 49.9613 51.4163 49.5547 52.3019C49.497 52.4277 49.4368 52.5539 49.3754 52.6826C49.0656 53.3322 48.7244 54.0474 48.5119 55.1006L46.8984 53.1066C46.7658 52.9427 46.5246 52.9134 46.3596 53.0409C46.1946 53.1685 46.1683 53.4047 46.3009 53.5685L48.461 56.2382C48.5935 56.402 48.8347 56.4314 48.9997 56.3038L51.6885 54.2248C51.8535 54.0972 51.8798 53.861 51.7472 53.6972C51.6147 53.5334 51.3735 53.504 51.2085 53.6315L49.2942 55.1117C49.4876 54.2348 49.779 53.6227 50.0681 53.0154C50.1306 52.884 50.1931 52.7528 50.2544 52.6193C50.706 51.6355 51.0569 50.5873 50.8635 48.5921C50.8435 48.3851 50.6564 48.2308 50.4457 48.2473Z"
        fill="#9DD9BB"
      />
      <path
        d="M66.1027 29.5169C65.7913 30.7357 66.5269 31.9761 67.7456 32.2875L84.446 36.5545C85.6648 36.8659 86.9052 36.1303 87.2166 34.9116L92.6824 13.5192C92.9938 12.3004 92.2583 11.06 91.0395 10.7486L74.3391 6.48159C73.1203 6.1702 71.8799 6.90576 71.5685 8.12452L66.1027 29.5169Z"
        fill="#FCB416"
      />
      <path
        d="M81.6549 20.1287C82.1633 19.9054 82.5888 19.5297 82.8721 19.054C83.1553 18.5783 83.2823 18.0261 83.2354 17.4743C83.1885 16.9225 82.9699 16.3986 82.6102 15.9754C82.2504 15.5521 81.7673 15.2507 81.2282 15.1129C80.689 14.9751 80.1205 15.0079 79.6018 15.2067C79.0832 15.4055 78.6401 15.7604 78.3342 16.2221C78.0283 16.6837 77.8749 17.2291 77.8952 17.7824C77.9156 18.3357 78.1087 18.8694 78.4478 19.3093C77.6477 19.413 76.8916 19.731 76.2599 20.2293C75.6283 20.7275 75.1448 21.3874 74.861 22.1385C74.8403 22.1934 74.8307 22.2518 74.8327 22.3104C74.8347 22.369 74.8483 22.4267 74.8727 22.4801C74.922 22.588 75.0123 22.6721 75.1237 22.714C75.2351 22.7558 75.3586 22.7519 75.4669 22.7031C75.5752 22.6544 75.6595 22.5647 75.7012 22.454C76.0108 21.6379 76.612 20.9648 77.3898 20.5632C78.1677 20.1617 79.0677 20.0597 79.9179 20.277C80.7682 20.4942 81.509 21.0154 81.9989 21.7409C82.4888 22.4664 82.6934 23.3453 82.5736 24.2099C82.5583 24.3185 82.5841 24.429 82.6459 24.52C82.7076 24.6111 82.801 24.6762 82.908 24.7029L82.9556 24.715C83.0722 24.731 83.1903 24.7005 83.2842 24.6301C83.3781 24.5598 83.4401 24.4552 83.4568 24.3394C83.5682 23.5419 83.4594 22.7286 83.1419 21.9869C82.8245 21.2451 82.3104 20.6027 81.6549 20.1287ZM80.1293 19.4138C79.7873 19.3264 79.4788 19.1402 79.2428 18.8786C79.0068 18.617 78.8538 18.2919 78.8032 17.9442C78.7526 17.5966 78.8067 17.2422 78.9586 16.9257C79.1106 16.6092 79.3535 16.3449 79.6567 16.1662C79.96 15.9875 80.3099 15.9025 80.6622 15.9218C81.0145 15.9412 81.3534 16.064 81.6361 16.2749C81.9187 16.4857 82.1325 16.7751 82.2502 17.1064C82.3679 17.4377 82.3844 17.796 82.2975 18.1361C82.181 18.5921 81.8871 18.9829 81.4805 19.2225C81.0739 19.4621 80.5878 19.5309 80.1293 19.4138Z"
        fill="white"
        stroke="white"
        stroke-width="0.5"
      />
      <rect
        width="9.68542"
        height="1.21068"
        rx="0.605339"
        transform="matrix(0.968875 0.24755 0.24755 -0.968875 73.1777 28.2617)"
        fill="white"
      />
      <rect
        width="14.157"
        height="1.21068"
        rx="0.605339"
        transform="matrix(0.968875 0.24755 0.24755 -0.968875 71.3633 25.2227)"
        fill="white"
      />
      <path
        d="M6.61255 44.3414C6.98347 45.5434 8.25856 46.2171 9.46054 45.8461L25.931 40.7635C27.133 40.3925 27.8067 39.1174 27.4358 37.9155L20.9251 16.8176C20.5542 15.6156 19.2791 14.9419 18.0771 15.3128L1.60664 20.3955C0.404658 20.7664 -0.269045 22.0415 0.101878 23.2435L6.61255 44.3414Z"
        fill="#12B76A"
      />
      <path
        d="M14.7229 28.0805C15.0399 27.6246 15.2065 27.082 15.1997 26.5284C15.1928 25.9747 15.0128 25.4375 14.6846 24.9915C14.3564 24.5454 13.8964 24.2127 13.3685 24.0397C12.8407 23.8666 12.2713 23.8618 11.7396 24.0259C11.2078 24.19 10.7401 24.5149 10.4016 24.9553C10.0631 25.3957 9.87058 25.9297 9.85082 26.4831C9.83106 27.0366 9.98505 27.5818 10.2914 28.0431C10.5977 28.5043 11.0411 28.8586 11.5599 29.0566C10.9319 29.5629 10.4531 30.229 10.1746 30.9838C9.89614 31.7386 9.82841 32.5538 9.97865 33.3426C9.98967 33.4001 10.012 33.455 10.0443 33.5039C10.0766 33.5528 10.1184 33.5949 10.1671 33.6277C10.2654 33.694 10.3864 33.7186 10.5032 33.6961C10.6201 33.6735 10.7234 33.6058 10.7903 33.5076C10.8572 33.4095 10.8822 33.289 10.86 33.1728C10.6978 32.3152 10.859 31.4272 11.3126 30.6785C11.7662 29.9298 12.4806 29.3729 13.3191 29.1141C14.1576 28.8553 15.0615 28.9129 15.8582 29.2757C16.6548 29.6386 17.2884 30.2814 17.6377 31.0812C17.6814 31.1818 17.7611 31.2626 17.8613 31.3079C17.9615 31.3533 18.0752 31.3601 18.1804 31.3269L18.2273 31.3125C18.3351 31.2652 18.4199 31.1775 18.4632 31.0685C18.5065 30.9594 18.5048 30.8379 18.4585 30.7304C18.1371 29.9921 17.6196 29.3554 16.9615 28.8886C16.3034 28.4218 15.5295 28.1424 14.7229 28.0805ZM13.0485 28.2676C12.7113 28.3716 12.3509 28.3739 12.013 28.2741C11.6751 28.1743 11.3749 27.9769 11.1502 27.7069C10.9255 27.4369 10.7865 27.1063 10.7508 26.7571C10.7151 26.4078 10.7842 26.0556 10.9495 25.7448C11.1148 25.4341 11.3688 25.1788 11.6793 25.0113C11.9899 24.8438 12.3431 24.7716 12.6943 24.8038C13.0454 24.836 13.3788 24.9712 13.6522 25.1922C13.9256 25.4133 14.1268 25.7103 14.2303 26.0457C14.3691 26.4954 14.3226 26.9822 14.1009 27.3988C13.8793 27.8155 13.5007 28.128 13.0485 28.2676Z"
        fill="white"
      />
      <path
        d="M11.814 38.996C11.9126 39.3154 12.2515 39.4945 12.5709 39.3959L20.6689 36.8969C20.9883 36.7983 21.1674 36.4594 21.0688 36.14C20.9702 35.8205 20.6313 35.6415 20.3119 35.7401L12.2139 38.239C11.8945 38.3376 11.7154 38.6765 11.814 38.996Z"
        fill="white"
      />
      <path
        d="M8.67938 37.3521C8.77796 37.6716 9.11685 37.8506 9.4363 37.7521L21.807 33.9346C22.1264 33.836 22.3055 33.4971 22.2069 33.1776C22.1083 32.8582 21.7694 32.6791 21.45 32.7777L9.07931 36.5952C8.75985 36.6938 8.5808 37.0327 8.67938 37.3521Z"
        fill="white"
      />
      <path
        d="M38.3785 45.0894C38.4469 46.3455 39.5206 47.3082 40.7767 47.2398L57.9881 46.302C59.2441 46.2336 60.2069 45.1599 60.1384 43.9038L58.9372 21.8569C58.8688 20.6009 57.7951 19.6381 56.539 19.7066L39.3276 20.6443C38.0716 20.7128 37.1088 21.7865 37.1773 23.0425L38.3785 45.0894Z"
        fill="#9DD9BB"
      />
      <path
        d="M50.1895 31.2802C50.6076 30.9147 50.9008 30.4288 51.0284 29.89C51.1559 29.3512 51.1115 28.7863 50.9013 28.274C50.691 27.7617 50.3254 27.3274 49.8553 27.0316C49.3851 26.7357 48.8339 26.593 48.2782 26.6233C47.7226 26.6535 47.1901 26.8553 46.7549 27.2005C46.3197 27.5457 46.0034 28.0171 45.8501 28.5492C45.6967 29.0814 45.7139 29.6477 45.8993 30.1694C46.0846 30.6912 46.4289 31.1424 46.8843 31.4603C46.1522 31.7992 45.5262 32.3293 45.073 32.9941C44.6199 33.6588 44.3565 34.4333 44.311 35.235C44.3078 35.2935 44.3161 35.3521 44.3356 35.4074C44.3551 35.4627 44.3854 35.5137 44.4247 35.5573C44.5041 35.6455 44.6154 35.6987 44.7343 35.7051C44.8531 35.7116 44.9697 35.6709 45.0584 35.5919C45.1471 35.5129 45.2006 35.4021 45.2072 35.2839C45.2579 34.4126 45.6295 33.5902 46.2511 32.9738C46.8727 32.3574 47.7008 31.9903 48.577 31.9426C49.4532 31.8948 50.3162 32.1698 51.0012 32.715C51.6861 33.2602 52.1448 34.0374 52.2898 34.898C52.3079 35.0062 52.3656 35.1039 52.4518 35.1722C52.538 35.2406 52.6467 35.2747 52.7568 35.268L52.8058 35.2654C52.9218 35.2456 53.0253 35.1811 53.0938 35.0858C53.1622 34.9905 53.1901 34.8722 53.1712 34.7567C53.0383 33.9625 52.6906 33.2193 52.1654 32.6069C51.6401 31.9945 50.9571 31.5358 50.1895 31.2802ZM48.5197 31.0557C48.1673 31.0749 47.8172 30.9898 47.5136 30.811C47.2099 30.6323 46.9665 30.368 46.814 30.0515C46.6615 29.7351 46.6068 29.3807 46.6568 29.0332C46.7068 28.6857 46.8593 28.3607 47.095 28.0993C47.3307 27.8379 47.639 27.6519 47.9809 27.5647C48.3228 27.4775 48.683 27.493 49.0158 27.6094C49.3487 27.7258 49.6394 27.9378 49.851 28.2185C50.0627 28.4992 50.1859 28.8361 50.205 29.1866C50.2306 29.6566 50.0674 30.1175 49.7514 30.4681C49.4353 30.8186 48.9923 31.03 48.5197 31.0557Z"
        fill="white"
      />
      <path
        d="M44.721 41.1647C44.7391 41.4985 45.0245 41.7544 45.3583 41.7362L53.8205 41.2751C54.1544 41.2569 54.4102 40.9716 54.392 40.6378C54.3738 40.3039 54.0885 40.0481 53.7547 40.0663L45.2925 40.5273C44.9586 40.5455 44.7028 40.8309 44.721 41.1647Z"
        fill="white"
      />
      <path
        d="M42.0784 38.8099C42.0966 39.1438 42.382 39.3996 42.7158 39.3814L55.6429 38.6771C55.9767 38.6589 56.2326 38.3736 56.2144 38.0397C56.1962 37.7059 55.9109 37.45 55.5771 37.4682L42.6499 38.1726C42.3161 38.1907 42.0602 38.4761 42.0784 38.8099Z"
        fill="white"
      />
    </svg>
  );
};

export default Connectors;
