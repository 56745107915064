const GoogleDrive = () => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 7.99724L8.22374 1.33309C8.3491 1.20544 8.49335 1.12171 8.63759 1.06543C7.52412 1.43192 7.01316 2.68239 7.01316 2.68239L0.11461 14.8535C0.0175459 15.2352 -0.00941522 15.5796 0.00271783 15.8831H7.5322L11.9999 7.99724Z"
        fill="#34A853"
      />
      <path
        d="M12.0001 7.99724L16.4678 15.8831H23.9973C24.0094 15.5796 23.9825 15.2352 23.8854 14.8535L16.9869 2.68239C16.9869 2.68239 16.4746 1.43192 15.3623 1.06543C15.5053 1.12171 15.6508 1.20544 15.7762 1.33309L12.0001 7.99724Z"
        fill="#FBBC05"
      />
      <path
        d="M11.9999 7.99839L15.7761 1.33427C15.6508 1.20662 15.5051 1.12288 15.3623 1.06661C15.1978 1.01308 15.0212 0.977386 14.8297 0.967773H14.6302H9.36974H9.17018C8.98011 0.976011 8.80215 1.0117 8.63761 1.06661C8.49478 1.12288 8.34912 1.20662 8.22375 1.33427L11.9999 7.99839Z"
        fill="#188038"
      />
      <path
        d="M7.53329 15.8838L3.80027 22.4725C3.80027 22.4725 3.67624 22.4121 3.50772 22.2871C4.03755 22.6949 4.55254 22.7813 4.55254 22.7813H19.2138C20.0226 22.7813 20.1912 22.4725 20.1912 22.4725C20.1938 22.4711 20.1952 22.4697 20.1978 22.4684L16.4676 15.8838H7.53329Z"
        fill="#4285F4"
      />
      <path
        d="M7.53374 15.8838H0.00427246C0.0420246 16.7788 0.430293 17.3018 0.430293 17.3018L0.713402 17.7931C0.733631 17.822 0.745763 17.8385 0.745763 17.8385L1.36187 18.9201L2.74507 21.3277C2.78551 21.4252 2.8327 21.5143 2.88259 21.5994C2.90145 21.6283 2.91898 21.6599 2.93921 21.6874C2.9446 21.6955 2.94999 21.7038 2.95539 21.712C3.1266 21.9536 3.31804 22.1403 3.50947 22.2871C3.67799 22.4135 3.80203 22.4725 3.80203 22.4725L7.53374 15.8838Z"
        fill="#1967D2"
      />
      <path
        d="M16.4678 15.8838H23.9972C23.9594 16.7788 23.5712 17.3018 23.5712 17.3018L23.2881 17.7931C23.2679 17.822 23.2558 17.8385 23.2558 17.8385L22.6396 18.9201L21.2564 21.3277C21.216 21.4252 21.1688 21.5143 21.119 21.5994C21.1001 21.6283 21.0825 21.6599 21.0623 21.6874C21.0569 21.6955 21.0515 21.7038 21.0461 21.712C20.8749 21.9536 20.6835 22.1403 20.492 22.2871C20.3235 22.4135 20.1995 22.4725 20.1995 22.4725L16.4678 15.8838Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleDrive;
