const BackButton = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.59961 5.86589L1.00918 5.27545C0.683087 5.60154 0.683087 6.13023 1.00918 6.45632L1.59961 5.86589ZM5.67584 11.123C6.00193 11.4491 6.53062 11.4491 6.85671 11.123C7.1828 10.7969 7.1828 10.2682 6.85671 9.94212L5.67584 11.123ZM6.85671 1.78965C7.1828 1.46357 7.1828 0.934872 6.85671 0.608785C6.53062 0.282697 6.00193 0.282697 5.67584 0.608785L6.85671 1.78965ZM6.85671 9.94212L2.19004 5.27545L1.00918 6.45632L5.67584 11.123L6.85671 9.94212ZM2.19004 6.45632L6.85671 1.78965L5.67584 0.608785L1.00918 5.27545L2.19004 6.45632Z"
        fill="#344054"
      />
    </svg>
  );
};

export default BackButton;
