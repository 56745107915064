const Dropbox = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 13.6407L7.05975 17.7969L0 13.1508L4.88174 9.21075L12 13.6407L19.1181 9.21008L23.9998 13.1509L16.9401 17.797L12 13.6407ZM7.05991 0.624023L0.000152588 5.27013L4.8819 9.21016L12.0002 4.78026L7.05991 0.624023ZM12.0145 14.5349L7.05997 18.679L4.93973 17.2835V18.8476L12.0145 23.124L19.0892 18.8476V17.2835L16.969 18.679L12.0145 14.5349ZM24 5.27019L16.9402 0.6241L12.0002 4.78026L19.1181 9.21008L24 5.27019Z"
        fill="#0F82E2"
      />
    </svg>
  );
};

export default Dropbox;
