const DataSources = () => {

  return (
    <svg
      width="285"
      height="300"
      viewBox="0 0 285 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="138.212" cy="183.279" r="116.72" fill="#EEF9F4" />
      <mask
        id="mask0_4995_31986"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="66"
        width="234"
        height="234"
      >
        <circle cx="138.212" cy="183.279" r="116.72" fill="#EEF9F4" />
      </mask>
      <g mask="url(#mask0_4995_31986)">
        <path
          d="M209.541 251.418H66.8828V286.965H67.1267C70.1458 298.489 100.819 307.545 138.212 307.545C175.605 307.545 206.278 298.489 209.297 286.965H209.541V251.418Z"
          fill="#53AC80"
        />
        <rect
          x="196.572"
          y="272.988"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <rect
          x="190.088"
          y="276.23"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <rect
          x="183.604"
          y="279.473"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <ellipse
          cx="84.5407"
          cy="284.336"
          rx="4.8161"
          ry="4.86334"
          fill="#EEF9F4"
        />
        <ellipse
          cx="138.212"
          cy="253.29"
          rx="71.329"
          ry="22.4505"
          fill="#9DD9BB"
        />
        <path
          d="M209.541 209.27H66.8828V244.816H67.1267C70.1458 256.34 100.819 265.397 138.212 265.397C175.605 265.397 206.278 256.34 209.297 244.816H209.541V209.27Z"
          fill="#53AC80"
        />
        <rect
          x="196.572"
          y="230.84"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <rect
          x="190.088"
          y="234.082"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <rect
          x="183.604"
          y="237.324"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <ellipse
          cx="84.5407"
          cy="242.188"
          rx="4.8161"
          ry="4.86334"
          fill="#EEF9F4"
        />
        <ellipse
          cx="138.212"
          cy="211.142"
          rx="71.329"
          ry="22.4505"
          fill="#9DD9BB"
        />
        <ellipse
          cx="138.212"
          cy="168.993"
          rx="71.329"
          ry="22.4505"
          fill="#CAEEDC"
        />
        <path
          d="M138.212 190.081C177.606 190.081 209.541 180.127 209.541 167.848V201.197H209.297C206.276 212.609 175.604 221.577 138.212 221.577C100.82 221.577 70.1478 212.609 67.127 201.197H66.8828V167.848C66.8828 180.127 98.8179 190.081 138.212 190.081Z"
          fill="#53AC80"
        />
        <path
          d="M67.1269 165.996C66.9652 166.607 66.8828 167.225 66.8828 167.848V165.996H67.1269Z"
          fill="#53AC80"
        />
        <path
          d="M209.297 165.996H209.541V167.848C209.541 167.225 209.458 166.607 209.297 165.996Z"
          fill="#53AC80"
        />
        <rect
          x="196.572"
          y="188.691"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <rect
          x="190.088"
          y="191.934"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <rect
          x="183.604"
          y="195.176"
          width="3.24223"
          height="16.2111"
          rx="1.62111"
          fill="#9DD9BB"
        />
        <ellipse
          cx="84.5407"
          cy="200.039"
          rx="4.8161"
          ry="4.86334"
          fill="#EEF9F4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7161 125.197C53.3062 125.108 53.8572 125.513 53.9469 126.103C54.2878 128.347 54.8727 130.501 55.8494 132.21C56.814 133.898 58.134 135.112 59.9754 135.613C61.7474 136.094 63.8256 135.9 66.2224 135.468C66.9005 135.346 67.6088 135.203 68.3361 135.057C70.0896 134.704 71.9541 134.328 73.7745 134.162C76.3962 133.923 79.1186 134.089 81.7043 135.379C83.7789 136.413 85.6817 138.129 87.3606 140.77L89.661 133.853C89.8494 133.286 90.4612 132.98 91.0276 133.168C91.594 133.357 91.9004 133.969 91.7121 134.535L88.6424 143.765C88.454 144.331 87.8422 144.637 87.2758 144.449L78.0462 141.379C77.4798 141.191 77.1734 140.579 77.3618 140.013C77.5501 139.446 78.162 139.14 78.7284 139.328L85.255 141.499C83.8279 139.385 82.2976 138.09 80.7397 137.313C78.6501 136.271 76.3789 136.095 73.9708 136.315C72.2712 136.47 70.5966 136.807 68.8946 137.15C68.1386 137.303 67.3773 137.456 66.6059 137.595C64.1819 138.032 61.6847 138.317 59.4084 137.699C56.8847 137.013 55.1484 135.339 53.9728 133.282C52.8093 131.246 52.17 128.798 51.8099 126.428C51.7203 125.838 52.126 125.287 52.7161 125.197Z"
        fill="#53AC80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.4766 141.657C60.9349 141.893 62.3164 141.775 63.1054 141.559C63.6812 141.401 64.2755 141.74 64.433 142.316C64.5904 142.892 64.2513 143.486 63.6756 143.644C62.5607 143.949 60.8651 144.072 59.1313 143.791C57.3951 143.51 55.4861 142.802 54.0852 141.313C53.6761 140.878 53.6969 140.194 54.1316 139.785C54.5663 139.376 55.2503 139.397 55.6593 139.832C56.6225 140.855 58.0206 141.422 59.4766 141.657Z"
        fill="#53AC80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.6632 130.141C78.1933 129.994 76.8215 130.196 76.047 130.459C75.4818 130.651 74.868 130.349 74.676 129.784C74.484 129.218 74.7866 128.605 75.3517 128.413C76.4461 128.041 78.1312 127.816 79.8788 127.991C81.6288 128.166 83.5772 128.758 85.0656 130.159C85.5001 130.568 85.5208 131.252 85.1116 131.687C84.7025 132.121 84.0185 132.142 83.5839 131.733C82.5606 130.769 81.1308 130.289 79.6632 130.141Z"
        fill="#53AC80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M221.68 102.256C221.089 102.132 220.516 102.501 220.401 103.079C219.86 105.781 219.207 108.684 218.112 111.152C217.016 113.623 215.545 115.501 213.466 116.365C209.212 118.133 204.729 120.084 200.778 123.698C197.21 126.962 194.128 131.529 191.941 138.419L188.269 132.203C187.965 131.687 187.297 131.505 186.777 131.795C186.258 132.086 186.084 132.739 186.388 133.254L191.35 141.655C191.654 142.17 192.322 142.353 192.842 142.062L201.305 137.329C201.825 137.039 201.999 136.385 201.695 135.87C201.39 135.354 200.722 135.172 200.203 135.462L194.09 138.881C196.177 132.407 199.053 128.228 202.278 125.278C205.927 121.94 210.09 120.109 214.344 118.341C217.146 117.176 218.918 114.731 220.113 112.036C221.311 109.337 222 106.229 222.54 103.527C222.656 102.949 222.271 102.379 221.68 102.256Z"
        fill="#FCB416"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.494 119.057C218.237 120.126 216.852 120.851 215.966 121.141C215.397 121.327 215.092 121.935 215.284 122.498C215.476 123.061 216.094 123.366 216.663 123.18C217.842 122.794 219.47 121.921 220.92 120.688C222.366 119.459 223.747 117.775 224.244 115.728C224.384 115.152 224.024 114.571 223.439 114.429C222.855 114.287 222.268 114.64 222.129 115.215C221.777 116.662 220.755 117.985 219.494 119.057Z"
        fill="#FCB416"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.336 119.416C201.641 118.454 203.017 117.759 203.885 117.453C204.448 117.254 204.743 116.637 204.543 116.075C204.343 115.513 203.725 115.218 203.162 115.417C202.072 115.801 200.508 116.603 199.049 117.679C197.604 118.745 196.136 120.172 195.399 121.853C195.16 122.399 195.409 123.036 195.957 123.275C196.504 123.515 197.142 123.266 197.381 122.72C197.897 121.543 199.017 120.389 200.336 119.416Z"
        fill="#FCB416"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.92 137.363C143.32 137.41 142.88 137.926 142.937 138.515C143.447 143.77 142.541 146.385 141.383 148.907C141.219 149.265 141.048 149.624 140.873 149.991C139.991 151.84 139.02 153.876 138.414 156.875L133.821 151.198C133.443 150.731 132.757 150.647 132.287 151.011C131.817 151.374 131.742 152.046 132.12 152.513L138.27 160.113C138.647 160.58 139.334 160.663 139.803 160.3L147.458 154.381C147.928 154.018 148.003 153.345 147.626 152.879C147.248 152.413 146.561 152.329 146.092 152.692L140.642 156.906C141.192 154.41 142.022 152.667 142.845 150.938C143.023 150.564 143.201 150.191 143.375 149.81C144.661 147.009 145.66 144.025 145.11 138.345C145.053 137.756 144.52 137.316 143.92 137.363Z"
        fill="#9DD9BB"
      />
      <path
        d="M188.492 84.0311C187.606 87.5009 189.7 91.0324 193.17 91.919L240.716 104.067C244.186 104.954 247.717 102.859 248.604 99.3897L264.165 38.4855C265.052 35.0157 262.957 31.4842 259.488 30.5976L211.941 18.4495C208.472 17.5629 204.94 19.6571 204.054 23.1269L188.492 84.0311Z"
        fill="#FCB416"
      />
      <path
        d="M232.775 57.3101C234.223 56.6744 235.434 55.6049 236.241 54.2505C237.047 52.8961 237.409 51.3241 237.275 49.7532C237.142 48.1823 236.519 46.6906 235.495 45.4856C234.471 44.2807 233.096 43.4224 231.561 43.0302C230.026 42.638 228.407 42.7314 226.93 43.2973C225.454 43.8633 224.192 44.8737 223.321 46.188C222.451 47.5022 222.014 49.055 222.072 50.6303C222.13 52.2056 222.68 53.725 223.645 54.9772C221.367 55.2727 219.214 56.178 217.416 57.5965C215.618 59.0151 214.241 60.8937 213.433 63.0322C213.374 63.1884 213.347 63.3546 213.353 63.5215C213.359 63.6883 213.397 63.8525 213.467 64.0046C213.607 64.3118 213.864 64.5513 214.181 64.6704C214.499 64.7895 214.85 64.7784 215.158 64.6396C215.467 64.5008 215.707 64.2456 215.825 63.9302C216.707 61.607 218.418 59.6907 220.633 58.5473C222.847 57.404 225.41 57.1139 227.83 57.7323C230.251 58.3508 232.36 59.8345 233.755 61.9C235.15 63.9655 235.732 66.468 235.391 68.9293C235.348 69.2385 235.421 69.5531 235.597 69.8123C235.773 70.0715 236.039 70.2569 236.343 70.3328L236.479 70.3674C236.811 70.4129 237.147 70.3261 237.414 70.1257C237.681 69.9254 237.858 69.6279 237.905 69.2979C238.223 67.0274 237.913 64.7122 237.009 62.6004C236.105 60.4885 234.642 58.6598 232.775 57.3101ZM228.432 55.2748C227.459 55.026 226.58 54.4958 225.908 53.7511C225.236 53.0064 224.801 52.0807 224.657 51.091C224.513 50.1014 224.667 49.0922 225.099 48.1912C225.532 47.2901 226.223 46.5377 227.087 46.029C227.95 45.5203 228.946 45.2781 229.949 45.3332C230.952 45.3883 231.917 45.7381 232.722 46.3384C233.527 46.9387 234.135 47.7625 234.47 48.7057C234.806 49.6489 234.852 50.6691 234.605 51.6372C234.273 52.9355 233.437 54.0481 232.279 54.7303C231.121 55.4125 229.738 55.6083 228.432 55.2748Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <rect
        width="27.5744"
        height="3.4468"
        rx="1.7234"
        transform="matrix(0.968875 0.24755 0.24755 -0.968875 208.643 80.457)"
        fill="white"
      />
      <rect
        width="40.305"
        height="3.4468"
        rx="1.7234"
        transform="matrix(0.968875 0.24755 0.24755 -0.968875 203.475 71.8086)"
        fill="white"
      />
      <path
        d="M19.1267 126.235C20.1827 129.657 23.8129 131.575 27.2349 130.519L74.1265 116.049C77.5485 114.993 79.4666 111.363 78.4105 107.941L59.8747 47.8749C58.8186 44.4529 55.1885 42.5348 51.7664 43.5908L4.87488 58.0613C1.45284 59.1173 -0.465189 62.7474 0.590827 66.1695L19.1267 126.235Z"
        fill="#12B76A"
      />
      <path
        d="M42.2168 79.9405C43.1193 78.6424 43.5938 77.0978 43.5743 75.5216C43.5547 73.9453 43.0421 72.4159 42.1078 71.146C41.1734 69.8761 39.8638 68.929 38.361 68.4363C36.8583 67.9436 35.2373 67.9299 33.7233 68.397C32.2094 68.8642 30.878 69.7891 29.9142 71.0429C28.9505 72.2967 28.4024 73.8172 28.3461 75.3928C28.2898 76.9684 28.7282 78.5207 29.6003 79.8338C30.4724 81.147 31.7348 82.1556 33.2119 82.7194C31.4239 84.1609 30.0607 86.0571 29.2679 88.206C28.4751 90.3549 28.2823 92.6759 28.71 94.9216C28.7414 95.0855 28.8049 95.2415 28.897 95.3808C28.989 95.5201 29.1078 95.6399 29.2464 95.7333C29.5265 95.9221 29.8708 95.9921 30.2035 95.928C30.5363 95.8639 30.8302 95.6709 31.0207 95.3915C31.2111 95.1121 31.2825 94.7691 31.2191 94.4381C30.7576 91.9965 31.2163 89.4685 32.5078 87.3369C33.7993 85.2054 35.833 83.6198 38.2202 82.8831C40.6075 82.1464 43.181 82.3102 45.4491 83.3433C47.7172 84.3764 49.5208 86.2063 50.5153 88.4834C50.6399 88.7698 50.8667 88.9998 51.152 89.129C51.4373 89.2582 51.761 89.2775 52.0604 89.1831L52.1939 89.1419C52.5008 89.0073 52.7422 88.7576 52.8655 88.4472C52.9888 88.1368 52.984 87.7908 52.8522 87.4847C51.937 85.3827 50.4637 83.5701 48.5901 82.2411C46.7165 80.9121 44.5134 80.1168 42.2168 79.9405ZM37.4499 80.473C36.4898 80.7693 35.4639 80.7758 34.5019 80.4917C33.5399 80.2076 32.685 79.6456 32.0454 78.8768C31.4057 78.108 31.01 77.167 30.9084 76.1727C30.8067 75.1784 31.0035 74.1755 31.4741 73.2908C31.9446 72.4062 32.6677 71.6794 33.5519 71.2026C34.4361 70.7257 35.4416 70.5201 36.4414 70.6118C37.4412 70.7034 38.3903 71.0883 39.1687 71.7176C39.9471 72.3469 40.5198 73.1924 40.8145 74.1473C41.2096 75.4277 41.0771 76.8134 40.4461 77.9998C39.8152 79.1861 38.7374 80.0757 37.4499 80.473Z"
        fill="white"
      />
      <path
        d="M33.9353 111.017C34.2159 111.926 35.1807 112.436 36.0902 112.155L59.145 105.041C60.0545 104.76 60.5643 103.795 60.2836 102.886C60.003 101.976 59.0382 101.467 58.1287 101.747L35.0739 108.862C34.1644 109.142 33.6546 110.107 33.9353 111.017Z"
        fill="white"
      />
      <path
        d="M25.011 106.337C25.2917 107.246 26.2565 107.756 27.1659 107.475L62.3853 96.607C63.2948 96.3263 63.8046 95.3615 63.5239 94.452C63.2432 93.5425 62.2784 93.0328 61.3689 93.3134L26.1496 104.182C25.2401 104.463 24.7303 105.427 25.011 106.337Z"
        fill="white"
      />
      <path
        d="M109.562 128.369C109.757 131.945 112.814 134.686 116.39 134.491L165.391 131.821C168.967 131.626 171.708 128.569 171.513 124.993L168.093 62.2256C167.898 58.6496 164.841 55.9087 161.266 56.1035L112.265 58.7733C108.689 58.9682 105.948 62.025 106.143 65.601L109.562 128.369Z"
        fill="#9DD9BB"
      />
      <path
        d="M143.188 89.0537C144.379 88.0131 145.214 86.6296 145.577 85.0957C145.94 83.5618 145.813 81.9537 145.215 80.4951C144.616 79.0366 143.575 77.8002 142.237 76.9578C140.898 76.1155 139.329 75.7092 137.747 75.7954C136.165 75.8816 134.649 76.456 133.41 77.4388C132.171 78.4215 131.271 79.7637 130.834 81.2787C130.398 82.7936 130.447 84.4059 130.974 85.8913C131.502 87.3767 132.482 88.6613 133.778 89.5664C131.694 90.5313 129.912 92.0405 128.622 93.9331C127.332 95.8257 126.582 98.0307 126.452 100.313C126.443 100.48 126.467 100.646 126.522 100.804C126.578 100.961 126.664 101.106 126.776 101.231C127.002 101.482 127.319 101.633 127.657 101.652C127.996 101.67 128.328 101.554 128.58 101.329C128.833 101.104 128.985 100.789 129.004 100.452C129.148 97.9717 130.206 95.6303 131.976 93.8755C133.746 92.1206 136.103 91.0754 138.598 90.9395C141.092 90.8036 143.549 91.5865 145.499 93.1386C147.449 94.6908 148.755 96.9034 149.168 99.3537C149.219 99.6617 149.384 99.9398 149.629 100.134C149.875 100.329 150.184 100.426 150.497 100.407L150.637 100.399C150.967 100.343 151.262 100.16 151.457 99.8883C151.652 99.6171 151.731 99.2803 151.677 98.9513C151.299 96.6902 150.309 94.5744 148.814 92.8308C147.318 91.0872 145.374 89.7815 143.188 89.0537ZM138.435 88.4146C137.431 88.4692 136.434 88.2268 135.57 87.7179C134.706 87.209 134.013 86.4565 133.578 85.5556C133.144 84.6547 132.988 83.6458 133.131 82.6565C133.273 81.6672 133.707 80.742 134.378 79.9978C135.049 79.2536 135.927 78.7239 136.901 78.4756C137.874 78.2273 138.899 78.2717 139.847 78.603C140.795 78.9343 141.622 79.5378 142.225 80.3371C142.827 81.1363 143.178 82.0955 143.233 83.0933C143.305 84.4313 142.841 85.7436 141.941 86.7415C141.041 87.7395 139.78 88.3413 138.435 88.4146Z"
        fill="white"
      />
      <path
        d="M127.62 117.195C127.671 118.145 128.484 118.874 129.434 118.822L153.526 117.509C154.476 117.458 155.205 116.645 155.153 115.695C155.101 114.744 154.289 114.016 153.338 114.068L129.247 115.38C128.296 115.432 127.568 116.244 127.62 117.195Z"
        fill="white"
      />
      <path
        d="M120.096 110.491C120.148 111.441 120.96 112.17 121.911 112.118L158.714 110.113C159.665 110.061 160.393 109.249 160.341 108.298C160.29 107.348 159.477 106.619 158.527 106.671L121.723 108.676C120.773 108.728 120.044 109.54 120.096 110.491Z"
        fill="white"
      />
    </svg>
  );
};

export default DataSources;
