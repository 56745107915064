import { csvFile, mcIcon, sync } from "../assets/icons";

interface AttributeOverwriteModalProps {
  mcHeader?: string;
  csvHeader?: string;
  overwrite: () => void;
  close: () => void;
}

const AttributeOverwriteModal: React.FC<AttributeOverwriteModalProps> = ({
  mcHeader,
  csvHeader,
  overwrite,
  close,
}) => {
  return (
    <div className="absolute w-screen h-screen left-0 top-0 bg-gray-950/[.4] flex justify-center items-center">
      <div className="w-164 p-6 bg-white rounded-xl">
        <div className="text-gray-800 leading-7">
          It looks like this attribute exists in your data as "
          <strong>{csvHeader}</strong>". Would you like to overwrite it or
          select a different attribute?
        </div>

        <div className="mt-10 flex items-center justify-between w-full">
          <div className="flex w-[46%] gap-2.5 px-3.5 py-2 bg-gray-10 border border-primary-green rounded-[8px]">
            <img src={mcIcon} alt="Marketing Cloud" />
            <div className="">
              <div className="text-gray-35 text-[12px] mb-0.5 leading-4">
                Marketing Cloud
              </div>
              <div className="font-semibold text-sm">{mcHeader}</div>
            </div>
          </div>

          <div>
            <img src={sync} alt="" />
          </div>

          <div className="flex w-[46%] gap-2.5 px-3.5 py-2 border border-summary-box-border rounded-[8px]">
            <img src={csvFile} alt="csv file" />
            <div className="">
              <div className="text-gray-35 text-[12px] mb-0.5 leading-4">
                CSV File
              </div>
              <div className="font-semibold text-sm">{csvHeader}</div>
            </div>
          </div>
        </div>

        <div className="mt-12 flex justify-between">
          <div
            onClick={close}
            className="py-3 px-5 leading-4 bg-white font-semibold hover:border hover:border-gray-300 rounded-md text-gray-70 cursor-pointer"
          >
            Cancel
          </div>
          <div className="flex justify-end gap-4">
            <div
              onClick={overwrite}
              className="py-3 px-5 leading-4 bg-white font-semibold border border-gray-40 hover:border-gray-300 rounded-md text-gray-70 cursor-pointer"
            >
              Skip to add attribute
            </div>
            <div
              onClick={overwrite}
              className="py-3 px-5 leading-4 font-semibold border border-blue-75 rounded-md bg-blue-75 hover:bg-primary-green hover:border-none text-white cursor-pointer"
            >
              Overwrite
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttributeOverwriteModal;
